import LocalizedStrings from "react-localization";

export const dataTableStrings = new LocalizedStrings({
  en: {
    noData: "No data to show. Please search for something.",
    rowsPerPage: "rows per page",
    pageX: "Page {0}",
    goTo: "Go to",
    goToPageX: "Go to page {0}",
    previousPage: "Previous page",
    nextPage: "Next page",
    xOutOfY: "{0} out of {1}",
    sortedAsc: "Sorted ascending",
    sortedDesc: "Sorted descending",
  },
  fr: {
    noData: "Pas de résultat pour votre recherche.",
    rowsPerPage: "nb de lignes par page",
    pageX: "Page {0}",
    goTo: "Aller à",
    goToPageX: "Aller à la page {0}",
    previousPage: "Page précédente",
    nextPage: "Page suivante",
    xOutOfY: "{0} en dehors de {1}",
    sortedAsc: "Tri croissant",
    sortedDesc: "Tri décroissant",
  },
});
