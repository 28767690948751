import LocalizedStrings from "react-localization";

export const mediaListingStrings = new LocalizedStrings({
  en: {
    title: "Media",
    mediaType: "Media Type",
    category: "Category",
    media: "Media",
  },
  fr: {
    title: "Média",
    mediaType: "Type de Média",
    category: "Catégorie",
    media: "Média",
  },
});
