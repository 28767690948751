import PropTypes from "prop-types";

import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, TextField } from "@mui/material/";

import { Search } from "@mui/icons-material";

import { initLocalization } from "../../../utils/helpers";
import { mappingKeysFiltersStrings } from "./locale";

export const MappingKeysFilters = ({ filters, setFilters, onSearch }) => {
  initLocalization(mappingKeysFiltersStrings);

  const handleSetFilters = (event) => {
    setFilters((prev) => {
      let next = {
        ...prev,
        [event.target.name]: event.target.value,
      };

      return next;
    });
  };

  const handleFieldKeydown = (event) => {
    if (event.key === "Enter" && event.target.value) {
      event.stopPropagation();
      onSearch();
    }
  };

  return (
    <Grid container className="creative-review-filters">
      <Grid
        xs={12}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
      >
        <Box
          sx={{
            "&": { flex: 1 },
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <TextField
            label={mappingKeysFiltersStrings.mediaType}
            size="small"
            name="mediaType"
            value={filters.mediaType}
            onChange={handleSetFilters}
            onKeyDown={handleFieldKeydown}
          />
        </Box>

        <Box
          sx={{
            "&": { flex: 1 },
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <TextField
            label={mappingKeysFiltersStrings.category}
            size="small"
            name="category"
            value={filters.category}
            onChange={handleSetFilters}
            onKeyDown={handleFieldKeydown}
          />
        </Box>

        <Box
          sx={{
            "&": { flex: 1 },
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <TextField
            label={mappingKeysFiltersStrings.media}
            size="small"
            name="media"
            value={filters.media}
            onChange={handleSetFilters}
            onKeyDown={handleFieldKeydown}
          />
        </Box>

        <Box
          sx={{
            "&": { flex: 1 },
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <TextField
            label={mappingKeysFiltersStrings.advertiser}
            size="small"
            name="advertiser"
            value={filters.advertiser}
            onChange={handleSetFilters}
            onKeyDown={handleFieldKeydown}
          />
        </Box>

        <Box
          sx={{
            "&": { flex: 1 },
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <TextField
            label={mappingKeysFiltersStrings.product}
            size="small"
            name="product"
            value={filters.product}
            onChange={handleSetFilters}
            onKeyDown={handleFieldKeydown}
          />
        </Box>

        <Button
          startIcon={<Search />}
          variant="contained"
          color="secondary"
          onClick={onSearch}
          // disabled={}
        >
          {mappingKeysFiltersStrings.search}
        </Button>
      </Grid>
    </Grid>
  );
};

MappingKeysFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};
