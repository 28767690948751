import { useContext } from "react";
import { AppContext } from "../../root/Root";
import { DefaultLayout } from "../../../layouts/DefaultLayout";
import Grid from "@mui/material/Unstable_Grid2";
import { CreativeReview } from "../../../components/common/creativeReview/CreativeReview";
import useReviewCreative from "../../../hooks/pathmatics/useReviewCreative";
import { DATA_SOURCE } from "../../../constants";
import "../../../style/common.scss";

export const Level3 = () => {
  useContext(AppContext);

  const useReviewCreativeOutput = useReviewCreative();

  return (
    <DefaultLayout>
      <Grid
        className="page-content"
        container
        rowSpacing={{ xs: 3, md: 0 }}
        columnSpacing={{ xs: 0, md: 2 }}
        padding={{ xs: 1, md: 2 }}
        disableEqualOverflow
      >
        <Grid xs={12}>
          <CreativeReview
            dataSource={DATA_SOURCE.pathmatics}
            useReviewCreativeOutput={useReviewCreativeOutput}
          />
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};
