import axios from "axios";
import dayjs from "dayjs";
import { API_ROUTE_REFRESH_TOKEN } from "../routes";
import { SESSION_REFRESH_INTERVAL } from "../constants";
import { debug } from "./helpers";

const Api = axios.create({
  baseURL: "",
  headers: {
    "Content-Type": "application/json",
  },
});

Api.interceptors.response.use(
  async (response) => {
    const storedSessionEnd = localStorage.getItem("sessionEnd");

    if (storedSessionEnd) {
      const sessionEnd = dayjs(storedSessionEnd);
      const currentDate = dayjs(new Date().toISOString());
      const remainingTime = sessionEnd.diff(currentDate, "m");

      if (remainingTime > 0 && remainingTime <= SESSION_REFRESH_INTERVAL) {
        const refresh = await axios.post(API_ROUTE_REFRESH_TOKEN);
        localStorage.setItem("sessionEnd", refresh?.data);
        debug.info("Session refreshed");
      }
    }

    return response;
  },
  (error) => {
    const status = error.response?.status;

    if (status === 401) {
      window.location.reload();
    }

    return Promise.reject(error);
  },
);

export default Api;
