import LocalizedStrings from "react-localization";

export const mediaListingFiltersStrings = new LocalizedStrings({
  en: {
    search: "Search",
    inputs: {
      media: {
        id: "Media ID",
        label: "Media Label",
      },
    },
  },
  fr: {
    search: "Recherche",
    inputs: {
      media: {
        id: "ID média",
        label: "Libellé média",
      },
    },
  },
});
