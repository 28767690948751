import LocalizedStrings from "react-localization";

export const creativeReviewStrings = new LocalizedStrings({
  en: {
    title: "Creative Review",
    resetFiltersButton: "Reset",
    productId: "Product ID",
    product: "Product",
    advertiser: "Advertiser",
    groupId: "Group ID",
    group: "Group",
    creativesCount: "Number",
    paperclipLabel: "Paperclip label",
    errors: {
      TotalResultsExceedsMaxAllowed:
        "There are too many results. Please refine your search.",
      NoResults: "No results found. Please refine your search.",
    },
  },
  fr: {
    title: "Revue Visuelles",
    resetFiltersButton: "Réinitialiser",
    productId: "ID Produit",
    product: "Produit",
    advertiser: "Annonceur",
    groupId: "ID Groupe",
    group: "Groupe",
    creativesCount: "Nombre",
    paperclipLabel: "L'étiquette du Trombone",
    errors: {
      TotalResultsExceedsMaxAllowed:
        "Il y a trop de résultats. Veuillez affiner votre recherche.",
      NoResults: "Aucun résultat trouvé. Veuillez affiner votre recherche.",
    },
  },
});
