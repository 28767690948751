import LocalizedStrings from "react-localization";

export const mappingKeysFiltersStrings = new LocalizedStrings({
  en: {
    mappingStatus: "Status",
    auto: "Auto",
    manual: "Manual",
    reject: "Reject",
    notMapped: "Not Mapped",
    manualDomainMapped: "Manual Domain Mapped",
    autoDomainMapped: "Auto Domain Mapped",
    productCreated: "Product Created",
    productRequested: "Product Requested",
    all: "All",
    search: "Search",
    searchBy: "By",
    startDate: "Start date",
    endDate: "End date",
  },
  fr: {
    mappingStatus: "Statut",
    auto: "Auto",
    manual: "Manual",
    reject: "Reject",
    notMapped: "Not Mapped",
    manualDomainMapped: "Manual Domain Mapped",
    autoDomainMapped: "Auto Domain Mapped",
    productCreated: "Product Created",
    productRequested: "Product Requested",
    all: "All",
    search: "Search",
    searchBy: "By",
    startDate: "Date de début",
    endDate: "Date de fin",
  },
});
