import { Alert, Snackbar } from "@mui/material";
import PropTypes from "prop-types";

export const AppAlert = ({
  open = false,
  type,
  position = { vertical: "top", horizontal: "right" },
  onClose = () => {},
  children,
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    onClose();
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={position}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        data-testid="snackbar"
        variant="filled"
        severity={type}
        onClose={handleClose}
        sx={{ width: "100%" }}
      >
        {children}
      </Alert>
    </Snackbar>
  );
};

AppAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  position: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node,
};
