import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { BurgerMenu } from "../burgerMenu/BurgerMenu";
import { LanguageMenu } from "../languageMenu/LanguageMenu";
import { AppContext } from "../../../pages/root/Root";
import { ROUTE_MAIN } from "../../../routes";
import kantarLogo from "../../../assets/kantar-media-logo.svg";
import "./style.scss";

export const Header = () => {
  const { user } = useContext(AppContext);
  const [menuOpened, setMenuOpened] = useState(false);

  const handleOpenMenu = () => {
    setMenuOpened(!menuOpened);
  };

  const handleCloseMenu = () => {
    setMenuOpened(false);
  };

  return (
    <>
      <AppBar className="header" position="static">
        <Toolbar
          sx={{
            flexWrap: "wrap",
            justifyContent: { xs: "center", sm: "normal" },
            pr: { xs: 0, sm: 2 },
            pb: { xs: 1, sm: 0 },
          }}
        >
          <IconButton
            className="header__menu"
            size="large"
            edge="start"
            aria-label="menu"
            onClick={handleOpenMenu}
          >
            <MenuIcon />
          </IconButton>
          <Divider orientation="vertical" flexItem />
          <span className="header__logo">
            <Link to={ROUTE_MAIN}>
              <img src={kantarLogo} alt="Kantar Media logo" />
            </Link>
          </span>
          <LanguageMenu />
          <Divider orientation="vertical" variant="middle" flexItem />
          <Avatar
            sx={{ width: 32, height: 32, my: 0, mr: 2, ml: { xs: 0, sm: 2 } }}
          />
          <Typography variant="body2" data-testid="user-name">
            {user?.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <BurgerMenu open={menuOpened} onClose={handleCloseMenu} />
    </>
  );
};
