import LocalizedStrings from "react-localization";

export const mappingCreationStrings = new LocalizedStrings({
  en: {
    addMappings: "Add Mappings",
  },
  fr: {
    addMappings: "Add Mappings",
  },
});
