import React from "react";
import { Box, MenuItem, TextField } from "@mui/material/";
import PropTypes from "prop-types";

import { initLocalization } from "../../../utils/helpers";
import { mappingStatusesStrings } from "./locale";

export const MappingStatuses = ({
  mappingStatus,
  mappingStatuses,
  handleMappingStatusChange,
}) => {
  initLocalization(mappingStatusesStrings);

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          width: "100%",
          background: "#fff",
        },
      }}
    >
      <TextField
        id="outlined-select-currency"
        select
        size="small"
        label={mappingStatusesStrings.label}
        value={mappingStatus}
        onChange={(event) => handleMappingStatusChange(event)}
        name="mappingStatus"
      >
        {mappingStatuses.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {mappingStatusesStrings[option.name]}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

MappingStatuses.propTypes = {
  mappingStatus: PropTypes.string.isRequired,
  mappingStatuses: PropTypes.array.isRequired,
  handleMappingStatusChange: PropTypes.func.isRequired,
};
