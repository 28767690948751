import { createBrowserRouter, RouterProvider } from "react-router-dom";
import axios from "axios";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { Root } from "./pages/root/Root";
import { Login } from "./pages/login/Login";
import { Level1 as PathmaticsLevel1 } from "./pages/pathmatics/level1/Level1";
import { Level2 as PathmaticsLevel2 } from "./pages/pathmatics/level2/Level2";
import { Level3 as PathmaticsLevel3 } from "./pages/pathmatics/level3/Level3";
import { Level1 as BisLevel1 } from "./pages/bis/level1/Level1";
import { Level2 as BisLevel2 } from "./pages/bis/level2/Level2";
import { Level3 as BisLevel3 } from "./pages/bis/level3/Level3";
import { MappingsView } from "./pages/selfPromotion/mappingsView/MappingsView";
import { Admin } from "./pages/admin/Admin";
import {
  ROUTE_LOGIN,
  ROUTE_ADMIN,
  ROUTE_MAIN,
  API_ROUTE_USER,
  ROUTE_PATHMATICS_LEVEL1,
  ROUTE_PATHMATICS_LEVEL2,
  ROUTE_PATHMATICS_LEVEL3,
  ROUTE_BIS_LEVEL1,
  ROUTE_BIS_LEVEL2,
  ROUTE_BIS_LEVEL3,
  ROUTE_MAPPINGS_VIEW,
} from "./routes";
import { theme } from "./theme";
import { debug } from "./utils/helpers";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const rootLoader = async () => {
  return axios
    .get(API_ROUTE_USER)
    .then((resp) => {
      localStorage.setItem("sessionEnd", resp.data.expirationDateTime);
      debug.info("Session ends at", resp.data.expirationDateTime);
      return resp.data;
    })
    .catch(() => null);
};

const router = createBrowserRouter([
  {
    id: "root",
    path: ROUTE_MAIN,
    element: <Root />,
    loader: rootLoader,
    children: [
      {
        index: true,
        element: <PathmaticsLevel1 />,
      },
      {
        path: ROUTE_PATHMATICS_LEVEL1,
        element: <PathmaticsLevel1 />,
      },
      {
        path: ROUTE_PATHMATICS_LEVEL2,
        element: <PathmaticsLevel2 />,
      },
      {
        path: ROUTE_PATHMATICS_LEVEL3,
        element: <PathmaticsLevel3 />,
      },
      {
        path: ROUTE_BIS_LEVEL1,
        element: <BisLevel1 />,
      },
      {
        path: ROUTE_BIS_LEVEL2,
        element: <BisLevel2 />,
      },
      {
        path: ROUTE_BIS_LEVEL3,
        element: <BisLevel3 />,
      },
      {
        path: ROUTE_MAPPINGS_VIEW,
        element: <MappingsView />,
      },
      {
        path: ROUTE_LOGIN,
        element: <Login />,
      },
      {
        path: ROUTE_ADMIN,
        element: <Admin />,
      },
    ],
  },
]);

function App() {
  return (
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </CssVarsProvider>
  );
}

export default App;
