import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_MEDIA_CHANNELS } from "../../routes";

export default function useGetMediaChannels(auto = false) {
  const [mediaChannels, setMediaChannels] = useState([]);

  const getMediaChannels = () => {
    Api.get(API_ROUTE_MEDIA_CHANNELS)
      .then((response) => {
        setMediaChannels(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!auto) {
      return;
    }
    getMediaChannels();
  }, [auto]);

  return {
    mediaChannels,
    getMediaChannels,
  };
}
