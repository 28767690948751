import LocalizedStrings from "react-localization";

export const mappingKeysFiltersStrings = new LocalizedStrings({
  en: {
    startDate: "Start date",
    endDate: "End date",
    mediaChannelsLabel: "Select media channel",
  },
  fr: {
    startDate: "Date de début",
    endDate: "Date de fin",
    mediaChannelsLabel: "Sélectionner un support",
  },
});
