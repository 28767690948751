import LocalizedStrings from "react-localization";

export const unauthorizedStrings = new LocalizedStrings({
  en: {
    title: "You don't have access on this page!",
    button: "Go back to the main page",
  },
  fr: {
    title: "Vous n'avez pas accès à cette page!",
    button: "Retourner à la page d'accueil",
  },
});
