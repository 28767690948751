import PropTypes from "prop-types";
import { Link } from "@mui/material";
import { SocialPageIcon } from "../socialPageIcon/SocialPageIcon";
import { createSocialPageUrl } from "../../../utils/helpers";

export const SocialPageLink = ({ row }) => {
  if (row.mediaChannelName && row.socialAccountName) {
    return (
      <Link
        href={createSocialPageUrl(row.mediaChannelName, row.socialAccountName)}
        target="_blank"
        role="link"
        sx={{
          "&": {
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          },
          "&:hover, &:active": {
            color: (theme) => theme.palette.primary.main,
          },
        }}
        alignItems="center"
        gap="0.5rem"
      >
        <SocialPageIcon mediaChannel={row.mediaChannelName} />
      </Link>
    );
  }
};

SocialPageLink.propTypes = {
  row: PropTypes.object.isRequired,
};
