import { memo } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Box, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { initLocalization } from "../../../utils/helpers";
import { advertiserListingFiltersStrings } from "./locale";
import { NumericInput } from "../../common/numericInput/NumericInput";
import {
  EXCLUDED_NUMERIC_FIELD_CHARS,
  SEARCH_FIELD_MIN_CHARS,
} from "../../../constants";

export const AdvertiserListingFilters = memo(function AdvertiserListingFilters({
  filters,
  setFilters,
  resetTable,
  getAdvertiserData,
}) {
  initLocalization(advertiserListingFiltersStrings);

  const handleFieldChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleFieldKeydown = (e) => {
    const idFields = ["advertiserId"];

    if (
      idFields.includes(e.target.name) &&
      EXCLUDED_NUMERIC_FIELD_CHARS.includes(e.key)
    ) {
      e.preventDefault();
    }

    if (e.key === "Enter") {
      e.stopPropagation();
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (disableSearch()) {
      return;
    }
    resetTable();
    getAdvertiserData({ ...filters });
  };

  const disableSearch = () => {
    const labelFields = ["advertiserLabel"];
    const insufficientChars = labelFields.some(
      (field) =>
        filters[field] !== "" &&
        filters[field].trim().length < SEARCH_FIELD_MIN_CHARS,
    );
    const allFieldsEmpty = ["advertiserId", "advertiserLabel"].every(
      (field) => filters[field].trim() === "",
    );

    return allFieldsEmpty || insufficientChars;
  };

  return (
    <Grid container>
      <Grid xsOffset="auto" paddingBottom={2}>
        <Button
          startIcon={<Search />}
          variant="contained"
          color="secondary"
          onClick={handleSearch}
          disabled={disableSearch()}
        >
          {advertiserListingFiltersStrings.search}
        </Button>
      </Grid>

      <Grid
        xs={12}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
        paddingY={3}
      >
        <Box
          flexGrow="1"
          sx={{
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <NumericInput
            id="outlined-disabled"
            label={advertiserListingFiltersStrings.inputs.advertiser.id}
            size="small"
            name="advertiserId"
            value={filters.mediaId}
            setValue={(val) =>
              handleFieldChange({
                target: { name: "advertiserId", value: val },
              })
            }
            onKeyDown={handleFieldKeydown}
          />
        </Box>
      </Grid>

      <Grid
        xs={12}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
        paddingBottom={3}
      >
        <Box
          flexGrow="1"
          sx={{
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <TextField
            id="outlined-disabled"
            label={advertiserListingFiltersStrings.inputs.advertiser.label}
            size="small"
            name="advertiserLabel"
            value={filters.mediaLabel}
            onKeyDown={handleFieldKeydown}
            onChange={handleFieldChange}
          />
        </Box>
      </Grid>
    </Grid>
  );
});

AdvertiserListingFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  resetTable: PropTypes.func.isRequired,
  getAdvertiserData: PropTypes.func.isRequired,
};
