import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { Paper, Typography, Backdrop, CircularProgress } from "@mui/material";
import { DataTable } from "../../common/dataTable/DataTable";
import { MediaListingFilters } from "../mediaListingFilters/MediaListingFilters";
import useMediaListing from "../../../hooks/selfPromotion/useMediaListing";
import { initLocalization } from "../../../utils/helpers";
import { mediaListingStrings } from "./locale";
import { DEFAULT_MEDIA_LISTING_FILTERS } from "../../../constants";

export const MediaListing = ({
  predefinedFilter,
  onSelectedMedia,
  isLoading = false,
}) => {
  initLocalization(mediaListingStrings);

  const lastSelected = useRef(null);
  const [filters, setFilters] = useState(DEFAULT_MEDIA_LISTING_FILTERS);
  const [selectedRows, setSelectedRows] = useState(() => new Set());

  const {
    isLoading: mediaLoading,
    mediaData,
    setMediaData,
    getMediaData,
  } = useMediaListing(predefinedFilter);

  const columns = [
    {
      field: "mediaType",
      headerName: mediaListingStrings.mediaType,
      minWidth: 30,
    },
    {
      field: "category",
      headerName: mediaListingStrings.category,
      minWidth: 20,
    },
    {
      field: "media",
      headerName: mediaListingStrings.media,
      minWidth: 50,
    },
  ];

  const resetTable = () => {
    setMediaData([]);
    setSelectedRows(() => new Set());
    onSelectedMedia([]);
  };

  const getSelected = (currentIndex, allowMultiple, indices) => {
    const prevArray = [...selectedRows];
    const current = prevArray.find((id) => id === mediaData[currentIndex].id);
    const currentSet =
      current && prevArray.length === 1 ? new Set([current]) : new Set();
    const next = allowMultiple ? new Set(selectedRows) : currentSet;

    indices.forEach((idx) => {
      const id = mediaData[idx].id;
      next.has(id) ? next.delete(id) : next.add(id);
    });

    return next;
  };

  const handleSelectRow = (event, index) => {
    const indices = new Set();
    let allowMultiple = false;

    indices.add(index);

    if (event.shiftKey) {
      const limits = [lastSelected.current ?? 0, index].sort((a, b) => a - b);
      const [start, end] = limits;

      for (let i = start; i <= end; i++) {
        indices.add(i);
      }

      indices.delete(lastSelected.current);
      allowMultiple = true;
    } else if (event.metaKey || event.ctrlKey) {
      allowMultiple = true;
    }

    const newSelection = getSelected(index, allowMultiple, indices);

    setSelectedRows(newSelection);
    onSelectedMedia(
      [...mediaData].filter((entry) => newSelection.has(entry.id)),
    );

    lastSelected.current = index;
  };

  return (
    <Paper sx={{ padding: "0.5rem", height: "100%", position: "relative" }}>
      <Grid container direction="column" height="100%">
        <Grid xs={12}>
          <Typography data-testid="media-listing-title">
            {mediaListingStrings.title}
          </Typography>
        </Grid>

        <Grid xs={12} marginTop={{ xs: 2, sm: 0 }}>
          <MediaListingFilters
            filters={filters}
            setFilters={setFilters}
            resetTable={resetTable}
            getMediaData={getMediaData}
          />
        </Grid>

        <Grid xs={12} display="flex" flexDirection="column" flexGrow="1">
          <DataTable
            selectedRows={[...selectedRows]}
            columns={columns}
            rows={mediaData}
            showPagination={false}
            allowSelection={true}
            showNoDataMessage={false}
            onSelectRow={handleSelectRow}
            tableMinWidth={700}
          />
        </Grid>
      </Grid>

      <Backdrop
        open={mediaLoading || isLoading}
        sx={{
          position: "absolute",
          color: "#fff",
          borderRadius: 1,
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

MediaListing.propTypes = {
  predefinedFilter: PropTypes.string.isRequired,
  onSelectedMedia: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
