import { mappingStatusesStrings } from "./locale";
import PropTypes from "prop-types";

import {
  initLocalization,
  createMappingStatusClasses,
} from "../../../utils/helpers";

export const MappingStatusColored = ({ value, row }) => {
  initLocalization(mappingStatusesStrings);

  return (
    <span
      data-testid="mapping-status-color"
      className={createMappingStatusClasses(row, value)}
    >
      {mappingStatusesStrings[value]}
    </span>
  );
};

MappingStatusColored.propTypes = {
  row: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
};
