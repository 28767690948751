import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_ROLES } from "../../routes";

export default function useGetUserRoles(auto = false) {
  const [userRoles, setUserRoles] = useState([]);

  const getUserRoles = () => {
    Api.get(API_ROUTE_ROLES)
      .then((res) => {
        setUserRoles(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!auto) {
      return;
    }
    getUserRoles();
  }, [auto]);

  return {
    userRoles,
    getUserRoles,
  };
}
