import LocalizedStrings from "react-localization";

export const loginStrings = new LocalizedStrings({
  en: {
    loginWithMs: "Log in with Microsoft",
  },
  fr: {
    loginWithMs: "Connectez-vous avec Microsoft",
  },
});
