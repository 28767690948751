import { useState } from "react";
import Api from "../../utils/Api";
import {
  API_ROUTE_SELF_PROMOTION_ADD_MAPPINGS,
  API_ROUTE_SELF_PROMOTION_REJECT_MAPPING_KEYS,
} from "../../routes";

export default function useRelationMapping() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(0);

  const addMappings = (payload = {}) => {
    setIsLoading(true);

    return Api.post(API_ROUTE_SELF_PROMOTION_ADD_MAPPINGS, { ...payload })
      .then(() => {
        setSuccess(new Date().getTime());
        return Promise.resolve();
      })
      .catch((error) => {
        const err = error?.response?.data?.errors?.ParentIds?.[0];
        setError(err);
        return Promise.reject(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const rejectMappingKeys = (payload = {}) => {
    setIsLoading(true);

    return Api.post(API_ROUTE_SELF_PROMOTION_REJECT_MAPPING_KEYS, [...payload])
      .then(() => {
        setSuccess(new Date().getTime());
        return Promise.resolve();
      })
      .catch((error) => {
        const err = error?.response?.data?.errors?.RejectedIds?.[0];
        setError(err);
        return Promise.reject(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    addMappings,
    rejectMappingKeys,
    error,
    setError,
    success,
  };
}
