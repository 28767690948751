import LocalizedStrings from "react-localization";

export const mappingStatusesStrings = new LocalizedStrings({
  en: {
    "Not mapped": "Not Mapped",
    Validated: "Validated",
    Rejected: "Rejected",
    Reject: "Rejected",
    Auto: "Auto",
    Manual: "Manual",
    "M/Domain Mapped": "M/Domain mapped",
    "A/Domain Mapped": "A/Domain mapped",
    "Waiting, product requested": "P. requested",
    "Waiting, product created": "P. created",
  },
  fr: {
    "Not mapped": "A Faire",
    Validated: "Validé",
    Rejected: "Rejeté",
    Reject: "Rejeté",
    Auto: "Auto",
    Manual: "Manual",
    "M/Domain Mapped": "M/Domain mapped",
    "A/Domain Mapped": "A/Domain mapped",
    "Waiting, product requested": "T. en cours",
    "Waiting, product created": "T. à valider",
  },
});
