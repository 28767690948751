import LocalizedStrings from "react-localization";

export const thresholdStrings = new LocalizedStrings({
  en: {
    title: "Paid Social Threshold",
    thresholdValue: "Paid Social Threshold",
    reset: "Reset",
    save: "Save",
    error: "Threshold must be a positive number!",
    modalDescription: "Are you sure you want to save threshold?",
    modalCancel: "No",
    modalSubmit: "Yes",
    successMessage: "Changes saved!",
    errorMessage: "Invalid threshold! Please use a valid threshold!",
  },
  fr: {
    title: "Paid Social Seuil d'investissement",
    thresholdValue: "Paid Social Seuil d'investissement",
    reset: "Réinitialiser",
    save: "Sauvegarder",
    error: "Le seuil doit être un nombre positif!",
    modalDescription: "Êtes-vous sûr de vouloir enregistrer le seuil?",
    modalCancel: "Non",
    modalSubmit: "Oui",
    successMessage: "Modifications sauvegardées!",
    errorMessage: "Invalid threshold! Please use a valid threshold!",
  },
});
