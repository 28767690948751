import { useContext, memo } from "react";
import { AppContext } from "../../../pages/root/Root";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers/";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  ListItemText,
  MenuItem,
  Checkbox,
} from "@mui/material/";
import { initLocalization } from "../../../utils/helpers";
import { mappingKeysFiltersStrings } from "./locale";
import {
  MAPPING_KEYS_DATE_FORMAT,
  MAPPING_FILTER_DATEPICKER_START_DATE,
  MAPPING_KEYS_API_DATE_FORMAT,
  START_DATE,
  END_DATE,
} from "../../../constants";

export const MappingKeysFilters = memo(function MappingKeysFilters({
  setPagination,
  setFilters,
  filters,
  mediaChannels,
}) {
  initLocalization(mappingKeysFiltersStrings);
  const { language } = useContext(AppContext);

  // Filter mapping keys interval
  const handleDateChange = (value, name) => {
    setPagination((pagination) => ({
      ...pagination,
      pageNumber: 0,
    }));

    setFilters({
      ...filters,
      [name]: dayjs(value).format(MAPPING_KEYS_API_DATE_FORMAT),
    });
  };
  // End filter mapping keys interval

  // Filter mapping keys by media channel
  const handleChangeSelectedMediaChannels = (event) => {
    const mediaChannels = event.target.value;
    setPagination((pagination) => ({
      ...pagination,
      pageNumber: 0,
    }));

    setFilters({
      ...filters,
      mediaChannelIds: mediaChannels,
    });
  };

  return (
    <Grid container>
      <Grid
        xs={12}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={language}
        >
          <DatePicker
            label={mappingKeysFiltersStrings.startDate}
            slotProps={{ textField: { size: "small", readOnly: true } }}
            sx={{
              "&": { flex: "1" },
              "& .MuiFormLabel-root": { maxWidth: "calc(100% - 2.875rem)" },
              "& .MuiFormLabel-root.MuiFormLabel-filled, & .MuiFormLabel-root.Mui-focused":
                {
                  maxWidth: "calc(133% - 2rem)",
                },
            }}
            format={MAPPING_KEYS_DATE_FORMAT}
            value={filters.startDate}
            minDate={dayjs(MAPPING_FILTER_DATEPICKER_START_DATE.default)}
            maxDate={dayjs(new Date())}
            onChange={(value) => handleDateChange(value, START_DATE)}
          />

          <DatePicker
            label={mappingKeysFiltersStrings.endDate}
            slotProps={{ textField: { size: "small", readOnly: true } }}
            sx={{
              "&": { flex: "1" },
              "& .MuiFormLabel-root": { maxWidth: "calc(100% - 2.875rem)" },
              "& .MuiFormLabel-root.MuiFormLabel-filled, & .MuiFormLabel-root.Mui-focused":
                {
                  maxWidth: "calc(133% - 2rem)",
                },
            }}
            format={MAPPING_KEYS_DATE_FORMAT}
            value={filters.endDate}
            minDate={dayjs(MAPPING_FILTER_DATEPICKER_START_DATE.default)}
            maxDate={dayjs(new Date())}
            onChange={(value) => handleDateChange(value, END_DATE)}
          />
        </LocalizationProvider>
        <Box
          sx={{
            "&": {
              flex: "1",
            },
            "& .MuiTextField-root": {
              width: "100%",
              background: "#fff",
            },
          }}
        >
          <FormControl sx={{ width: "100%" }} size="small">
            <InputLabel
              id="media-channels-label"
              sx={{
                "&": { maxWidth: "calc(100% - 2.875rem)" },
                "&.MuiFormLabel-filled, &.Mui-focused": {
                  maxWidth: "calc(133% - 2rem)",
                },
              }}
            >
              {mappingKeysFiltersStrings.mediaChannelsLabel}
            </InputLabel>
            <Select
              labelId="media-channels-label"
              id="media-channels-select"
              multiple
              value={filters.mediaChannelIds}
              onChange={handleChangeSelectedMediaChannels}
              input={
                <OutlinedInput
                  label={mappingKeysFiltersStrings.mediaChannelsLabel}
                />
              }
              renderValue={(selected) => (
                <Typography>
                  {selected.map(
                    (mediaChannelId) =>
                      mediaChannels?.find((e) => e.id === mediaChannelId).name +
                      " ",
                  )}
                </Typography>
              )}
            >
              {mediaChannels.map((mediaChannel) => (
                <MenuItem key={mediaChannel.id} value={mediaChannel.id}>
                  <Checkbox
                    checked={
                      filters.mediaChannelIds.indexOf(mediaChannel.id) > -1
                    }
                  />
                  <ListItemText primary={mediaChannel.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
});

MappingKeysFilters.propTypes = {
  setPagination: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  mediaChannels: PropTypes.arrayOf(PropTypes.object).isRequired,
};
