import Grid from "@mui/material/Unstable_Grid2";
import { Button, Typography } from "@mui/material/";
import { ROUTE_MAIN } from "../../routes";
import { useNavigate } from "react-router-dom";

import { initLocalization } from "../../utils/helpers";
import { unauthorizedStrings } from "./locale";

export const Unauthorized = () => {
  initLocalization(unauthorizedStrings);

  const navigate = useNavigate();

  const goToMain = () => navigate(ROUTE_MAIN);

  return (
    <Grid
      container
      flexDirection="column"
      gap="2rem"
      alignItems="center"
      justifyContent="center"
      margin="3rem"
    >
      <Typography variant="h5">{unauthorizedStrings.title}</Typography>
      <Button variant="contained" onClick={goToMain}>
        {unauthorizedStrings.button}
      </Button>
    </Grid>
  );
};
