import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Grid from "@mui/material/Unstable_Grid2";
import { Paper, Typography, Backdrop, CircularProgress } from "@mui/material";
import { DataTable } from "../../common/dataTable/DataTable";
import { AdvertiserListingFilters } from "../advertiserListingFilters/AdvertiserListingFilters";
import useAdvertiserListing from "../../../hooks/selfPromotion/useAdvertiserListing";
import { initLocalization } from "../../../utils/helpers";
import { advertiserListingStrings } from "./locale";
import {
  DEFAULT_ADVERTISER_LISTING_FILTERS,
  MAPPING_KEYS_DATE_FORMAT,
} from "../../../constants";

export const AdvertiserListing = ({
  onSelectedAdvertisers,
  isLoading = false,
}) => {
  initLocalization(advertiserListingStrings);

  const lastSelected = useRef(null);
  const [filters, setFilters] = useState(DEFAULT_ADVERTISER_LISTING_FILTERS);
  const [selectedRows, setSelectedRows] = useState(() => new Set());

  const {
    isLoading: advertisersLoading,
    advertiserData,
    setAdvertiserData,
    getAdvertiserData,
  } = useAdvertiserListing();

  const columns = [
    {
      field: "id",
      headerName: advertiserListingStrings.advertiserId,
      minWidth: 10,
    },
    {
      field: "advertiser",
      headerName: advertiserListingStrings.advertiser,
      minWidth: 65,
    },
    {
      field: "activation",
      headerName: advertiserListingStrings.activation,
      minWidth: 5,
    },
    {
      field: "creationDate",
      headerName: advertiserListingStrings.creationDate,
      minWidth: 20,
      format: (value) => dayjs(value).format(MAPPING_KEYS_DATE_FORMAT),
    },
  ];

  const resetTable = () => {
    setAdvertiserData([]);
    setSelectedRows(() => new Set());
    onSelectedAdvertisers([]);
  };

  const getSelected = (currentIndex, allowMultiple, indices) => {
    const prevArray = [...selectedRows];
    const current = prevArray.find(
      (id) => id === advertiserData[currentIndex].id,
    );
    const currentSet =
      current && prevArray.length === 1 ? new Set([current]) : new Set();
    const next = allowMultiple ? new Set(selectedRows) : currentSet;

    indices.forEach((idx) => {
      const id = advertiserData[idx].id;
      next.has(id) ? next.delete(id) : next.add(id);
    });

    return next;
  };

  const handleSelectRow = (event, index) => {
    const indices = new Set();
    let allowMultiple = false;

    indices.add(index);

    if (event.shiftKey) {
      const limits = [lastSelected.current ?? 0, index].sort((a, b) => a - b);
      const [start, end] = limits;

      for (let i = start; i <= end; i++) {
        indices.add(i);
      }

      indices.delete(lastSelected.current);
      allowMultiple = true;
    } else if (event.metaKey || event.ctrlKey) {
      allowMultiple = true;
    }

    const newSelection = getSelected(index, allowMultiple, indices);

    setSelectedRows(newSelection);
    onSelectedAdvertisers(
      [...advertiserData].filter((entry) => newSelection.has(entry.id)),
    );

    lastSelected.current = index;
  };

  return (
    <Paper sx={{ padding: "0.5rem", height: "100%", position: "relative" }}>
      <Grid container direction="column" height="100%">
        <Grid xs={12}>
          <Typography data-testid="advertiser-listing-title">
            {advertiserListingStrings.title}
          </Typography>
        </Grid>

        <Grid xs={12} marginTop={{ xs: 2, sm: 0 }}>
          <AdvertiserListingFilters
            filters={filters}
            setFilters={setFilters}
            resetTable={resetTable}
            getAdvertiserData={getAdvertiserData}
          />
        </Grid>

        <Grid xs={12} display="flex" flexDirection="column" flexGrow="1">
          <DataTable
            selectedRows={[...selectedRows]}
            columns={columns}
            rows={advertiserData}
            showPagination={false}
            allowSelection={true}
            showNoDataMessage={false}
            onSelectRow={handleSelectRow}
            tableMinWidth={750}
          />
        </Grid>
      </Grid>

      <Backdrop
        open={advertisersLoading || isLoading}
        sx={{
          position: "absolute",
          color: "#fff",
          borderRadius: 1,
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

AdvertiserListing.propTypes = {
  onSelectedAdvertisers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
