import { useContext, useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { AppContext } from "../../../pages/root/Root";
import { initLocalization } from "../../../utils/helpers";
import { LANGUAGES } from "../../../constants";
import { languageMenuStrings } from "./locale";
import "./style.scss";

export const LanguageMenu = () => {
  initLocalization(languageMenuStrings);
  const { language: selectedLanguage, changeLanguage } = useContext(AppContext);
  const [anchor, setAnchor] = useState(null);

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleItemClick = (event, lang) => {
    changeLanguage(lang);
    setAnchor(null);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <>
      <List
        id="lang-menu-button"
        component="nav"
        aria-label={languageMenuStrings.changeLanguage}
        disablePadding
      >
        <ListItem
          aria-haspopup="listbox"
          aria-controls="lang-menu"
          aria-expanded={Boolean(anchor)}
        >
          <ListItemButton
            className="language-menu__button"
            onClick={handleClick}
            data-testid="language-button"
          >
            <ListItemIcon sx={{ minWidth: 36 }}>
              <LanguageIcon />
            </ListItemIcon>
            <ListItemText sx={{ minWidth: 22 }}>
              {selectedLanguage}
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Menu
        id="lang-menu"
        className="language-menu__list"
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "lang-menu-button",
          role: "listbox",
        }}
      >
        {Object.values(LANGUAGES).map((lang, index) => (
          <MenuItem
            key={lang}
            selected={lang === selectedLanguage}
            onClick={(event) => handleItemClick(event, lang)}
          >
            <Typography variant="body2">{lang}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
