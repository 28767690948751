import { useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Chip } from "@mui/material";
import useCountdownTimer from "../../../hooks/common/useCountdownTimer";
import { initLocalization } from "../../../utils/helpers";
import { lockTimerStrings } from "./locale";
import "./style.scss";

export const MappingKeysTimer = ({ scheduleTimer, setSelectedRowsCB }) => {
  initLocalization(lockTimerStrings);
  const { startTimer, resetTimer, isTimerRunning, formatTime } =
    useCountdownTimer(setSelectedRowsCB);

  useEffect(() => {
    if (scheduleTimer) startTimer();
    if (!scheduleTimer) resetTimer();
  }, [resetTimer, startTimer, scheduleTimer]);

  return (
    <>
      {isTimerRunning && (
        <Typography variant="subtitle2" fontSize={12}>
          {lockTimerStrings.lockExpiresIn}:{" "}
          <Chip className="mapping-keys-timer" label={formatTime()}></Chip>
        </Typography>
      )}
    </>
  );
};

MappingKeysTimer.propTypes = {
  scheduleTimer: PropTypes.bool.isRequired,
  setSelectedRowsCB: PropTypes.func.isRequired,
};
