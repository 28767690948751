import { useEffect, useRef, useState } from "react";
import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import { API_ROUTE_NOTIFICATION_HUB } from "../../routes";
import { debug, isDevelopment } from "../../utils/helpers";

const MAX_START_ATTEMPTS = 3;

export default function useNotificationHub() {
  const startAttempts = useRef(1);
  const [connection, setConnection] = useState(null);
  const [message, setMessage] = useState(null);

  async function start() {
    try {
      await connection.start();
      debug.info(`Connected to SignalR with ID: ${connection.connectionId}`);
    } catch (err) {
      debug.error(err);

      if (startAttempts.current >= MAX_START_ATTEMPTS) {
        debug.error("Unable to connect to SignalR.");
        return;
      }

      startAttempts.current = startAttempts.current + 1;
      setTimeout(start, 5000);
    }
  }

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(API_ROUTE_NOTIFICATION_HUB)
      .withAutomaticReconnect()
      .configureLogging(isDevelopment ? LogLevel.Debug : LogLevel.None)
      .build();

    connect.on("accountsLockStatusChanged", (message) => {
      setMessage(JSON.parse(message));
      debug.log(JSON.parse(message));
    });

    connect.on("bisCampaignLockStatusChanged", (message) => {
      setMessage(JSON.parse(message));
      debug.log(JSON.parse(message));
    });

    connect.onreconnecting((error) => {
      debug.error(`SignalR connection lost. Reconnecting... Error: ${error}`);
    });

    connect.onreconnected((connectionId) => {
      debug.info(`Reconnected to SignalR with ID: ${connectionId}`);
    });

    connect.onclose(() => {
      debug.info("SignalR connection closed.");
    });

    setConnection(connect);

    return () => {
      connect.stop();
    };
  }, []);

  useEffect(() => {
    if (connection?.state === HubConnectionState.Disconnected) {
      start();
    }
  }, [connection]);

  return {
    message,
  };
}
