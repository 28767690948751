import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_MEDIA_LISTING } from "../../routes";

export default function useMediaListing(predefinedFilter, auto = false) {
  const [isLoading, setIsLoading] = useState(false);
  const [mediaData, setMediaData] = useState([]);

  const getMediaData = (payload = {}, showLoader = true) => {
    setIsLoading(showLoader);

    Api.post(API_ROUTE_MEDIA_LISTING, {
      ...payload,
      predefinedFilter,
    })
      .then((response) => {
        setMediaData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!auto) {
      return;
    }
    getMediaData();
  }, [auto]);

  return {
    isLoading,
    mediaData,
    setMediaData,
    getMediaData,
  };
}
