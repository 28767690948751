import LocalizedStrings from "react-localization";

export const poolMetadataStrings = new LocalizedStrings({
  en: {
    metadataTitle: "Metadata",
    id: "ID",
    type: "Type",
    date: "Date",
    campaign: "Campaign",
    product: "Product",
    domain: "Domain",
    site: "Site",
    link: "Link",
    updateL1: "Update L1",
    errors: {
      MappingStatusChangeNotAllowed: "Mapping status change not allowed",
      LinesNotLockedByUser:
        "Lines are not locked or are locked by a different user",
      ProductDoesNotExist: "Product does not exist",
      generic: "An error occurred",
    },
    campaignUpdated: "The campaign has been updated",
  },
  fr: {
    metadataTitle: "Metadata",
    id: "ID",
    type: "Type",
    date: "Date",
    campaign: "Campaign",
    product: "Product",
    domain: "Domain",
    site: "Site",
    link: "Link",
    updateL1: "Correction L1",
    errors: {
      MappingStatusChangeNotAllowed:
        "Changement de statut de mapping non autorisé",
      LinesNotLockedByUser: "Lignes non verrouillées par l'utilisateur",
      ProductDoesNotExist: "Product does not exist",
      generic: "Une erreur s'est produite",
    },
    campaignUpdated: "La campagne a été corrigée",
  },
});
