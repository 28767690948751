import { useMemo, useState, useRef } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-material-ui-carousel";
import { Typography, Link, Box } from "@mui/material";
import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
} from "@mui/icons-material";

import { initLocalization, createSocialPageUrl } from "../../../utils/helpers";
import { viewMappingDetailsStrings } from "./locale";
import mediaPlaceholderImage from "../../../assets/media-placeholder.jpg";
import "./style.scss";

const BUFFER_SIZE = 7;

const createChunk = (currentIndex, dataSource, offset = 0) => {
  let chunk = [];
  const prevBufferLastIndex = currentIndex - BUFFER_SIZE;
  const nextBufferLastIndex = currentIndex + BUFFER_SIZE;

  // prev buffer
  if (prevBufferLastIndex < 0) {
    chunk = [
      ...chunk,
      ...dataSource.slice(prevBufferLastIndex),
      ...dataSource.slice(0, BUFFER_SIZE + prevBufferLastIndex),
    ];
  } else {
    chunk = [...chunk, ...dataSource.slice(prevBufferLastIndex, currentIndex)];
  }

  // next buffer
  if (nextBufferLastIndex >= dataSource.length) {
    chunk = [
      ...chunk,
      ...dataSource.slice(currentIndex, nextBufferLastIndex),
      ...dataSource.slice(0, nextBufferLastIndex + 1 - dataSource.length),
    ];
  } else {
    chunk = [
      ...chunk,
      ...dataSource.slice(currentIndex, nextBufferLastIndex + 1),
    ];
  }

  // chunk offsetting
  // Note: due to the fact that the carousel's index prop doesn't work correctly
  // when the same value is passed to it, the chunk array has to be offset by
  // one item alternatively. The original array index will be kept in chunkIndex.
  chunk = JSON.parse(JSON.stringify(chunk));

  chunk.forEach((item, idx) => (item.chunkIndex = idx));

  const offsetChunk = [...chunk.slice(offset), ...chunk.slice(0, offset)];

  return offsetChunk;
};

export const ViewMappingDetails = ({ mappingDetailsData }) => {
  initLocalization(viewMappingDetailsStrings);

  const chunkOffset = useRef(0);

  const mappingDetailsArray = useMemo(() => {
    let index = 0;
    let items = [];

    mappingDetailsData.forEach((mapping) => {
      if (mapping.creativeDetails.length <= 0) {
        items.push({
          index,
          id: mapping.id,
          name: mapping.name,
          friendlyName: mapping.friendlyName,
          mediaChannelName: mapping.mediaChannelName,
          creativeDetails: {
            socialAccountId: "",
            creativeText: "",
            location: null,
            mediaTypeName: "",
          },
        });

        index++;
      } else {
        mapping.creativeDetails.forEach((creative) => {
          items.push({
            index,
            name: mapping.name,
            friendlyName: mapping.friendlyName,
            mediaChannelName: mapping.mediaChannelName,
            creativeDetails: {
              id: creative.id,
              socialAccountId: creative.socialAccountId,
              creativeText: creative.creativeText,
              location: creative.location,
              mediaTypeName: creative.mediaTypeName,
            },
            accountUrl: createSocialPageUrl(
              mapping.mediaChannelName,
              mapping.name,
            ),
          });

          index++;
        });
      }
    });

    return items;
  }, [mappingDetailsData]);

  const useChunks = mappingDetailsArray.length > 2 * BUFFER_SIZE + 1;

  const [mappingDataChunk, setMappingDataChunk] = useState(
    useChunks ? createChunk(0, mappingDetailsArray) : mappingDetailsArray,
  );

  return (
    <Grid
      className="view-mappings"
      maxWidth="80rem"
      padding="2rem"
      margin="1rem auto"
      backgroundColor="#fff"
    >
      <Carousel
        className="view-mappings__carousel-wrapper"
        {...(useChunks && { index: BUFFER_SIZE - chunkOffset.current })}
        animation="slide"
        autoPlay={false}
        navButtonsAlwaysVisible={true}
        fullHeightHover={false}
        height="37.5rem"
        {...(useChunks && {
          onChange: (current, prev) => {
            const chunkIndex = mappingDataChunk[current].chunkIndex;

            if (
              chunkIndex + 1 >= mappingDataChunk.length ||
              chunkIndex - 1 < 0
            ) {
              const offset = 1 - chunkOffset.current;

              chunkOffset.current = offset;

              setMappingDataChunk(
                createChunk(
                  mappingDataChunk[current].index,
                  mappingDetailsArray,
                  offset,
                ),
              );
            }
          },
        })}
        navButtonsWrapperProps={{
          className: "view-mappings__nav-buttons-wrapper-props",
        }}
        navButtonsProps={{
          className: "view-mappings__nav-buttons-props",
          disabled: mappingDetailsArray?.length <= 1,
        }}
        PrevIcon={
          <Box className="view-mappings__slider-buttons view-mappings__slider-prev">
            <ArrowCircleLeftOutlined
              sx={{ width: "2.5rem", height: "2.5rem" }}
            />
            {viewMappingDetailsStrings.prevButton}
          </Box>
        }
        NextIcon={
          <Box className="view-mappings__slider-buttons view-mappings__slider-next">
            {viewMappingDetailsStrings.nextButton}
            <ArrowCircleRightOutlined
              sx={{ width: "2.5rem", height: "2.5rem" }}
            />
          </Box>
        }
        IndicatorIcon={mappingDataChunk.map(
          (item) => `${item.index + 1} / ${mappingDetailsArray.length}`,
        )}
        indicatorIconButtonProps={{
          className: "view-mappings__indicator-icon-button-props",
          disabled: true,
        }}
        activeIndicatorIconButtonProps={{
          className: "view-mappings__active-indicator-icon-button-props",
        }}
        indicatorContainerProps={{
          className: "view-mappings__indicator-container-props",
        }}
      >
        {mappingDataChunk?.map((item) => (
          <Grid
            sx={{
              border: "1px solid #ccc",
              borderRadius: "0.5rem",
              height: "100%",
            }}
            key={item.name}
          >
            <Grid container sx={{ height: "100%" }}>
              <Grid
                container
                flexDirection="column"
                flex="2"
                justifyContent="center"
                gap="0.625rem"
                padding="2rem"
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {viewMappingDetailsStrings.socialAccountName}
                </Typography>
                <Typography>{item.name}</Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {viewMappingDetailsStrings.mediaChannel}
                </Typography>
                <Typography>{item.mediaChannelName}</Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {viewMappingDetailsStrings.url}
                </Typography>
                <Link
                  href={item.mediaChannelName.length > 0 ? item.accountUrl : ""}
                  target="_blank"
                >
                  {item.mediaChannelName.length > 0 ? item.accountUrl : ""}
                </Link>
                <Typography sx={{ fontWeight: "bold" }}>
                  {viewMappingDetailsStrings.description}
                </Typography>
                <Typography sx={{ maxHeight: "17rem", overflowY: "auto" }}>
                  {item.creativeDetails.creativeText}
                </Typography>
              </Grid>
              <Grid
                flex="3"
                container
                alignItems="center"
                width="100%"
                height="100%"
              >
                {item?.creativeDetails?.mediaTypeName.length === 0 && (
                  <img
                    src={mediaPlaceholderImage}
                    alt={`${item.name}`}
                    className="view-mappings__slide-image-placeholder"
                  />
                )}
                {item?.creativeDetails?.mediaTypeName === "image" && (
                  <img
                    src={item.creativeDetails.location}
                    alt={`${item.name}`}
                    className="view-mappings__slide-image"
                  />
                )}
                {item?.creativeDetails?.mediaTypeName === "video" && (
                  <video
                    data-testid="view-mappings__slide-video"
                    className="view-mappings__slide-video"
                    controls
                  >
                    <source src={item.creativeDetails.location} />
                  </video>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Carousel>
    </Grid>
  );
};

ViewMappingDetails.propTypes = {
  mappingDetailsData: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
};
