import { useEffect, useState, useCallback } from "react";
import {
  DEFAULT_COUNTDOWN_TIMER,
  DEFAULT_COUNTDOWN_INTERVAL,
} from "../../constants";

/*
    This hook allows you to easily set an initial time, start the timer, and reset it when needed. 
    - can be initalized with 3 default values:a callback fn (for different action cases when timer reaches 0), defaultTime(total countdown timer) and interval(how fast the timer should run). 
    - remainingTime: this state variable keeps track of the time remaining in the countdown. 
    - startTimer: triggers the countdown by setting the isTimerRunning state to true.
    - resetTimer: stops the countdown (if it's running) and resets the timer to its initial value.
    - If the remainingTime reaches 0, it clears the timer, resets the timer, and calls the callback function.
*/

export default function useCountdownTimer(
  callback = () => {},
  defaultTime = DEFAULT_COUNTDOWN_TIMER,
  interval = DEFAULT_COUNTDOWN_INTERVAL,
) {
  const [remainingTime, setRemainingTime] = useState(defaultTime);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const resetTimer = useCallback(() => {
    setIsTimerRunning(false);
    setRemainingTime(defaultTime);
  }, [defaultTime]);

  const startTimer = () => {
    setIsTimerRunning(true);
  };

  const formatTime = () => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    let timerId;

    if (isTimerRunning && remainingTime > 0) {
      timerId = setTimeout(
        () => setRemainingTime((prevTime) => prevTime - interval / 1000),
        interval,
      );
    }

    if (remainingTime === 0) {
      callback();
      resetTimer();
    }

    return () => clearTimeout(timerId);
  }, [interval, isTimerRunning, remainingTime, resetTimer, callback]);

  return { remainingTime, startTimer, resetTimer, formatTime, isTimerRunning };
}
