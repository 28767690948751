import LocalizedStrings from "react-localization";

export const actionButtonsStrings = new LocalizedStrings({
  en: {
    mapDomain: "Map Domain",
    unMapDomain: "Un-map Domain",
    paperClip: "Paper clip",
    reject: "Reject",
    mapCampaign: "Map to campaign",
  },
  fr: {
    mapDomain: "Map Domain",
    unMapDomain: "Un-map Domain",
    paperClip: "Trombone",
    reject: "Rejeter",
    mapCampaign: "Map to campaign",
  },
});
