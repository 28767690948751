import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import mediaPlaceholderImage from "../../../assets/media-placeholder.jpg";
import { CREATIVES_MEDIA_DOMAIN } from "../../../constants";

import "./style.scss";

export const PoolMedia = ({ creativeDetails }) => {
  return (
    <Grid paddingY="2.6rem" className="pool-media-wrapper">
      {creativeDetails?.mediaTypeName.length === 0 && (
        <img
          src={mediaPlaceholderImage}
          alt={(
            creativeDetails?.campaignVm?.campaignTitle ||
            creativeDetails?.creativeText
          )?.slice(0, 100)}
          className="pool-media-wrapper__media-placeholder"
        />
      )}
      {creativeDetails?.mediaTypeName === "image" && (
        <img
          src={creativeDetails.location}
          alt={(
            creativeDetails?.campaignVm?.campaignTitle ||
            creativeDetails?.creativeText
          )?.slice(0, 100)}
          className="pool-media-wrapper__media-image"
        />
      )}
      {creativeDetails?.mediaTypeName === "video" && (
        <video className="pool-media-wrapper__media-video" controls muted>
          <source src={creativeDetails.location} />
        </video>
      )}
      {creativeDetails?.mediaTypeName?.toLowerCase() === "html5" && (
        <iframe
          className="pool-media-wrapper__media-iframe"
          title={creativeDetails?.id}
          src={creativeDetails?.location?.split(CREATIVES_MEDIA_DOMAIN)?.[1]}
        />
      )}
    </Grid>
  );
};

PoolMedia.propTypes = {
  creativeDetails: PropTypes.object.isRequired,
};
