import { useState, useRef, useContext } from "react";
import { AppContext } from "../../root/Root";
import { DefaultLayout } from "../../../layouts/DefaultLayout";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Typography,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
} from "@mui/material";
import {
  AttachFileOutlined,
  BlockOutlined,
  SendOutlined,
  ArrowForwardIosOutlined,
  FilterAltOutlined,
  Close,
} from "@mui/icons-material";
import { AppAlert } from "../../../components/common/appAlert/AppAlert";

import { actionButtonsStrings } from "./locale";
import { productListingStrings } from "../../../components/bis/productListing/locale";

import { CreativeClassification } from "../../../components/common/creativeClassification/CreativeClassification";
import { ProductListing } from "../../../components/bis/productListing/ProductListing";
import { usePoolSize } from "../../../hooks/bis/usePoolSize";
import useMappingKeyStatus from "../../../hooks/common/useMappingKeyStatus";
import {
  ACTIVATION_FILTER_OPTIONS,
  BIS_POOL_SIZES_ARRAY,
  DATA_SOURCE,
  MAPPING_OPTIONS,
} from "../../../constants";

import { initLocalization } from "../../../utils/helpers";

import "../../../style/common.scss";

export const Level2 = () => {
  useContext(AppContext);
  initLocalization(actionButtonsStrings);

  const [error, setError] = useState(null);
  const [selectedCreatives, setSelectedCreatives] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [paperClipLabel, setPaperClipLabel] = useState("");
  const [poolSize, setPoolSize] = useState(BIS_POOL_SIZES_ARRAY[0]);
  const [resetSelectedCreatives, setResetSelectedCreatives] = useState(true);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const productFilter = useRef({});
  const previousPoolSize = useRef(poolSize);

  const filterLabel = isFilterActive
    ? productFilter.current.label
    : selectedProduct.productLabel;

  const {
    isLoading: poolLoading,
    poolData,
    totalPoolSize,
    setPoolData,
    setTotalPoolSize,
    getPoolData,
  } = usePoolSize(true);
  const {
    isLoading: actionLoading,
    paperClipMappingKey,
    rejectMappingKey,
    validateMappingKey,
  } = useMappingKeyStatus(MAPPING_OPTIONS.bisCreative);

  let creative = {};

  if (selectedCreatives?.length === 1) {
    creative = {
      id: selectedCreatives[0]?.id,
      productId: selectedCreatives[0]?.campaignVm.product?.sourceProductId,
    };
  }

  const handlePoolSizeChange = async (event, newPoolSize) => {
    if (newPoolSize !== null) {
      previousPoolSize.current = newPoolSize;
      setPoolSize(newPoolSize);
      await getPoolData({
        previousPoolSize: poolSize,
        currentPoolSize: newPoolSize,
        productId: isFilterActive ? productFilter.current.id : null,
      });
      setSelectedCreatives([]);
      setResetSelectedCreatives(true);
    }
  };

  const handleNextPool = async () => {
    await getPoolData({
      currentPoolSize: poolSize,
      previousPoolSize: previousPoolSize.current,
      productId: isFilterActive ? productFilter.current.id : null,
      nextPool: true,
    });
    setSelectedCreatives([]);
    setResetSelectedCreatives(true);
  };

  const handleProductFilter = async () => {
    await getPoolData({
      currentPoolSize: poolSize,
      previousPoolSize: poolSize,
      productId: isFilterActive ? null : selectedProduct.productId,
      nextPool: false,
    });
    productFilter.current = isFilterActive
      ? {}
      : {
          id: selectedProduct.productId,
          label: selectedProduct.productLabel,
        };
    setIsFilterActive((prev) => !prev);
    setSelectedCreatives([]);
    setResetSelectedCreatives(true);
  };

  const disablePaperClipButton = () => {
    return !selectedCreatives?.length || !paperClipLabel || actionLoading;
  };

  const disableRejectButton = () => {
    return !selectedCreatives?.length || actionLoading;
  };

  const disableValidateButton = () => {
    return !selectedProduct.id || !selectedCreatives?.length || actionLoading;
  };

  const buildPayload = (selectedCreatives, selectedProductId) => {
    const payload = {
      lineIds: selectedCreatives.map((row) => row.id),
    };

    if (selectedProductId) payload.productId = selectedProductId;

    return payload;
  };

  const updateCreatives = (lineIds) => {
    setPoolData((prev) =>
      [...prev].filter((creative) => !lineIds.includes(creative.id)),
    );
    setTotalPoolSize((prev) => {
      let next = prev - lineIds.length;
      return next < 0 ? 0 : next;
    });
    setSelectedCreatives([]);
  };

  const handlePaperClipFieldChange = (e) => {
    setPaperClipLabel(e.target.value);
  };

  const handlePaperClipMappingKeys = () => {
    const data = {
      ...buildPayload(selectedCreatives),
      paperclipLabel: paperClipLabel,
    };
    paperClipMappingKey(data)
      .then(() => updateCreatives(data.lineIds))
      .catch((err) => setError(err));
  };

  const handleRejectMappingKeys = () => {
    const data = buildPayload(selectedCreatives);
    rejectMappingKey(data)
      .then(() => updateCreatives(data.lineIds))
      .catch((err) => setError(err));
  };

  const handleValidateMappingKeys = () => {
    const data = buildPayload(selectedCreatives, selectedProduct.id);
    validateMappingKey(data)
      .then(() => updateCreatives(data.lineIds))
      .catch((err) => setError(err));
  };

  const handleCloseError = () => setError(false);

  return (
    <DefaultLayout>
      <AppAlert
        key={error}
        open={Boolean(error)}
        type="error"
        onClose={handleCloseError}
      >
        {productListingStrings.errors[error]}
      </AppAlert>

      <Grid
        className="page-content"
        container
        rowSpacing={{ xs: 3, md: 0 }}
        columnSpacing={{ xs: 0, md: 2 }}
        padding={{ xs: 1, md: 2 }}
        disableEqualOverflow
      >
        <Grid md={7}>
          <CreativeClassification
            dataSource={DATA_SOURCE.bis}
            poolData={poolData}
            totalPoolSize={totalPoolSize}
            isLoading={poolLoading}
            selectedCreatives={selectedCreatives}
            selectedProduct={selectedProduct}
            resetSelectedCreatives={resetSelectedCreatives}
            onCreativesSelection={setSelectedCreatives}
            onResetSelectedCreatives={setResetSelectedCreatives}
          />
        </Grid>
        <Grid md={5}>
          <Grid container direction="column" columns={12} height="100%">
            <Grid
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              paddingY={2}
            >
              <Grid display="flex" alignItems="center" gap={1}>
                <TextField
                  size="small"
                  value={filterLabel ?? ""}
                  disabled
                  sx={{
                    "& .MuiInputBase-root": {
                      background: isFilterActive ? "#e8def8" : "#fff",
                    },
                    "& input:disabled": {
                      color: "#333",
                      WebkitTextFillColor: "#333",
                    },
                  }}
                />
                <Button
                  variant="contained"
                  color={isFilterActive ? "error" : "primary"}
                  startIcon={isFilterActive ? <Close /> : <FilterAltOutlined />}
                  onClick={handleProductFilter}
                  disabled={!selectedProduct.id && !isFilterActive}
                  sx={{
                    minWidth: 0,
                    px: 1.5,
                    "& .MuiButton-startIcon": { margin: 0 },
                    "& .MuiButton-startIcon>:first-of-type": { fontSize: 26 },
                  }}
                ></Button>
              </Grid>
              <Grid display="flex" alignItems="center" gap={2}>
                <Typography>{actionButtonsStrings.poolSize}</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={poolSize}
                  exclusive
                  onChange={handlePoolSizeChange}
                  aria-label="Pool size"
                  size="large"
                  sx={{ height: "75%" }}
                >
                  {BIS_POOL_SIZES_ARRAY.map((value, index) => (
                    <ToggleButton
                      key={index}
                      value={value}
                      disabled={actionLoading}
                    >
                      {value}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                <Button
                  endIcon={<ArrowForwardIosOutlined />}
                  onClick={handleNextPool}
                  disabled={actionLoading}
                >
                  {actionButtonsStrings.nextPool}
                </Button>
              </Grid>
            </Grid>
            <Grid xs={12} flexGrow="1">
              <ProductListing
                activationFilterOption={ACTIVATION_FILTER_OPTIONS.creatives}
                mappingKey={creative}
                allowSearch={true}
                onSelectedProductRow={setSelectedProduct}
                shouldRefresh={1}
                isLoading={actionLoading}
              />
            </Grid>
            <Grid
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap={2}
              paddingY={3}
              justifyContent="space-between"
            >
              <Grid
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                gap={2}
              >
                <TextField
                  id="outlined-disabled"
                  label={actionButtonsStrings.paperClipLabel}
                  size="small"
                  value={paperClipLabel}
                  inputProps={{ maxLength: 255 }}
                  onChange={handlePaperClipFieldChange}
                />
                <Button
                  startIcon={<AttachFileOutlined />}
                  variant="outlined"
                  size="large"
                  disabled={disablePaperClipButton()}
                  onClick={handlePaperClipMappingKeys}
                >
                  {actionButtonsStrings.paperClip}
                </Button>
              </Grid>
              <Grid
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                gap={2}
              >
                <Button
                  startIcon={<BlockOutlined />}
                  variant="outlined"
                  color="error"
                  size="large"
                  disabled={disableRejectButton()}
                  onClick={handleRejectMappingKeys}
                >
                  {actionButtonsStrings.reject}
                </Button>
                <Button
                  startIcon={<SendOutlined />}
                  variant="outlined"
                  color="success"
                  size="large"
                  disabled={disableValidateButton()}
                  onClick={handleValidateMappingKeys}
                >
                  {actionButtonsStrings.validate}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};
