import LocalizedStrings from "react-localization";

export const productListingStrings = new LocalizedStrings({
  en: {
    title: "Product Listing",
    productID: "Prod. ID",
    productLabel: "Product",
    advertiserLabel: "Advertiser",
    activationID: "Act.",
    creationDate: "Date",
    groupLabel: "Group",
    socialAccountName: "Account name",
    errors: {
      MappingStatusChangeNotAllowed: "Mapping status change not allowed",
      LinesNotLockedByUser:
        "Lines are not locked or are locked by a different user",
      ProductDoesNotExist: "Product does not exist",
    },
  },
  fr: {
    title: "Produits",
    productID: "ID Prod.",
    productLabel: "Produit",
    advertiserLabel: "Annonceur",
    activationID: "Act.",
    creationDate: "Date",
    groupLabel: "Groupe",
    socialAccountName: "SAN",
    errors: {
      MappingStatusChangeNotAllowed:
        "Changement de statut de mapping non autorisé",
      LinesNotLockedByUser: "Lignes non verrouillées par l'utilisateur",
      ProductDoesNotExist: "Product does not exist",
    },
  },
});
