import { useRef, useEffect } from "react";

// The useIsMounted hook tracks whether a React component is mounted or not, providing a check to handle actions or avoid side effects when unmounted.
export default function useIsMounted() {
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
  }, []);
  return isMountedRef.current;
}
