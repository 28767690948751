import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_SELF_PROMOTION_MAPPING_KEYS } from "../../routes";
import { debug } from "../../utils/helpers";
import { DEFAULT_MAPPINGS_VIEW_PAYLOAD } from "../../constants";

export default function useMappingKeys(auto = false) {
  const [isLoading, setIsLoading] = useState(false);
  const [mappingKeys, setMappingKeys] = useState([]);

  const getMappingKeys = (payload = {}, showLoader = true) => {
    setIsLoading(showLoader);

    return Api.post(API_ROUTE_SELF_PROMOTION_MAPPING_KEYS, {
      ...DEFAULT_MAPPINGS_VIEW_PAYLOAD,
      ...payload,
    })
      .then((response) => {
        setMappingKeys(response.data);
      })
      .catch((error) => {
        debug.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!auto) {
      return;
    }
    getMappingKeys();
  }, [auto]);

  return {
    isLoading,
    mappingKeys,
    getMappingKeys,
    setMappingKeys,
  };
}
