import { useState } from "react";
import { generatePath } from "react-router-dom";
import Api from "../../utils/Api";
import { API_ROUTE_UPDATE_USER_ROLE } from "../../routes";

export default function useUpdateUserRole() {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const addUserRole = (userId, roleId) => {
    Api.post(generatePath(API_ROUTE_UPDATE_USER_ROLE, { id: userId }), {
      roleId,
    }).then(() => setOpenSnackbar(true));
  };

  const modifyUserRole = (userId, roleId) => {
    Api.put(generatePath(API_ROUTE_UPDATE_USER_ROLE, { id: userId }), {
      roleId,
    }).then(() => setOpenSnackbar(true));
  };

  const deleteUserRole = (userId) => {
    Api.delete(generatePath(API_ROUTE_UPDATE_USER_ROLE, { id: userId })).then(
      () => setOpenSnackbar(true),
    );
  };

  return {
    openSnackbar,
    setOpenSnackbar,
    addUserRole,
    modifyUserRole,
    deleteUserRole,
  };
}
