import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import { NUMERIC_INPUT_LIMIT } from "../../../constants";

export const NumericInput = ({ setValue, ...props }) => {
  return (
    <TextField
      {...props}
      inputProps={{ type: "number" }}
      onPaste={(e) => {
        e.preventDefault();
        const pastedValue = e.clipboardData.getData("text");

        if (!Number(pastedValue)) {
          return;
        }

        setValue(pastedValue.slice(0, NUMERIC_INPUT_LIMIT));
      }}
      onChange={(e) => setValue(e.target.value.slice(0, NUMERIC_INPUT_LIMIT))}
    />
  );
};

NumericInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  ...TextField.propTypes,
};
