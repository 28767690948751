import LocalizedStrings from "react-localization";

export const viewMappingDetailsStrings = new LocalizedStrings({
  en: {
    prevButton: "Prev",
    nextButton: "Next",
    socialAccountName: "Social account name",
    mediaChannel: "Media channel",
    url: "Link",
    description: "Description",
  },
  fr: {
    prevButton: "Préc",
    nextButton: "Suiv",
    socialAccountName: "Nom du Social Account",
    mediaChannel: "Support",
    url: "Lien",
    description: "Description",
  },
});
