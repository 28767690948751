// App routes. Template: ROUTE_<name>
export const ROUTE_MAIN = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_ADMIN = "/admin";
export const ROUTE_PATHMATICS_LEVEL1 = "/pathmatics/level1";
export const ROUTE_PATHMATICS_LEVEL2 = "/pathmatics/level2";
export const ROUTE_PATHMATICS_LEVEL3 = "/pathmatics/level3";
export const ROUTE_BIS_LEVEL1 = "/bis/level1";
export const ROUTE_BIS_LEVEL2 = "/bis/level2";
export const ROUTE_BIS_LEVEL3 = "/bis/level3";
export const ROUTE_MAPPINGS_VIEW = "/self-promotion/mappings-view";

// API routes. Template: API_ROUTE_<name>
export const API_ROUTE_SIGN_IN = "/api/v1/diane-user/signin";
export const API_ROUTE_LOG_OUT = "/api/v1/diane-user/logout";
export const API_ROUTE_REFRESH_TOKEN = "/api/v1/diane-user/token/refresh";
export const API_ROUTE_USER = "/api/v1/diane-user/user";
export const API_ROUTE_ROLES = "/api/v1/diane-user/roles";
export const API_ROUTE_USER_ROLES = "/api/v1/diane-user/user-roles";
export const API_ROUTE_UPDATE_USER_ROLE = "/api/v1/diane-user/user/:id/role";
export const API_ROUTE_UPDATE_THRESHOLD =
  "/api/v1/diane.mapping.service/settings";
export const API_ROUTE_MAPPING_UNLOCK_ALL_LINES =
  "/api/v1/diane.mapping.service/locked-lines/unlock";
export const API_ROUTE_MAPPING_STATUSES =
  "/api/v1/diane.mapping.service/mapping-statuses";
export const API_ROUTE_UPDATE_MAPPING_STATUSES =
  "/api/v1/diane.mapping.service/mapping";
export const API_ROUTE_PRODUCT_LISTING =
  "/api/v1/diane.taxonomy.service/products/summary";
export const API_ROUTE_NOTIFICATION_HUB = "/notification-hub";

// Pathmatics
export const API_ROUTE_MAPPING_LOCKED_LINES =
  "/api/v1/diane.mapping.service/social-accounts/locked-lines";
export const API_ROUTE_MAPPING_KEYS =
  "/api/v1/diane.mapping.service/social-accounts/summary";
export const API_ROUTE_MAPPING_DETAILS =
  "/api/v1/diane.mapping.service/social-accounts/details";
export const API_ROUTE_MEDIA_CHANNELS =
  "/api/v1/diane.mapping.service/media-channels";
export const API_ROUTE_PATHMATICS_POOL_SIZE =
  "/api/v1/diane.mapping.service/creatives/user-pool";
export const API_ROUTE_PATHMATICS_VALID_VISUAL =
  "/api/v1/diane.mapping.service/creative/valid-visual";
export const API_ROUTE_PATHMATICS_REVIEW_CREATIVE =
  "/api/v1/diane.mapping.service/creatives/review-summary";
export const API_ROUTE_PATHMATICS_REVIEW_DETAILS =
  "/api/v1/diane.mapping.service/creatives/review-details";

// BIS
export const API_ROUTE_BIS_MAPPING_KEYS_LOCKED_LINES =
  "/api/v1/diane.mapping.service/bis-campaigns/locked-lines";
export const API_ROUTE_BIS_MAPPING_KEYS =
  "/api/v1/diane.mapping.service/bis-campaigns/summary";
export const API_ROUTE_BIS_POOL_SIZE =
  "/api/v1/diane.mapping.service/bis-creative";
export const API_ROUTE_BIS_REVIEW_CREATIVE =
  "/api/v1/diane.mapping.service/bis-creatives/review-summary";
export const API_ROUTE_BIS_REVIEW_DETAILS =
  "/api/v1/diane.mapping.service/bis-creatives/review-details";

// Self Promotion
export const API_ROUTE_SELF_PROMOTION_MAPPING_KEYS =
  "/api/v1/diane.mapping.service/relation-mapping/self-promotion/summary";
export const API_ROUTE_SELF_PROMOTION_REJECT_MAPPING_KEYS =
  "/api/v1/diane.mapping.service/relation-mapping/self-promotion/reject";
export const API_ROUTE_SELF_PROMOTION_ADD_MAPPINGS =
  "/api/v1/diane.mapping.service/relation-mapping/self-promotion";
export const API_ROUTE_MEDIA_LISTING =
  "/api/v1/diane.taxonomy.service/media/summary";
export const API_ROUTE_ADVERTISER_LISTING =
  "/api/v1/diane.taxonomy.service/advertisers/summary";
