import { useState, useEffect } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_UPDATE_THRESHOLD } from "../../routes";

export default function useUpdateThreshold(auto = false) {
  const [threshold, setThreshold] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageType, setMessageType] = useState("success");

  const getThreshold = () => {
    Api.get(API_ROUTE_UPDATE_THRESHOLD)
      .then(function (response) {
        setThreshold(response.data.threshold);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateThreshold = (threshold) => {
    Api.put(API_ROUTE_UPDATE_THRESHOLD, {
      threshold,
    })
      .then(() => {
        setMessageType("success");
        setOpenSnackbar(true);
      })
      .catch(() => {
        setMessageType("error");
        setOpenSnackbar(true);
      });
  };

  useEffect(() => {
    if (!auto) {
      return;
    }
    getThreshold();
  }, [auto]);

  return {
    threshold,
    messageType,
    openSnackbar,
    setOpenSnackbar,
    setThreshold,
    getThreshold,
    updateThreshold,
  };
}
