import LocalizedStrings from "react-localization";

export const actionButtonsStrings = new LocalizedStrings({
  en: {
    poolSize: "Pool Size",
    nextPool: "Next Pool",
    paperClipLabel: "Enter Paper Clip label",
    paperClip: "Paper clip",
    reject: "Reject",
    validate: "Validate",
  },
  fr: {
    poolSize: "Pool Size",
    nextPool: "Next Pool",
    paperClipLabel: "L'étiquette du Trombone",
    paperClip: "Trombone",
    reject: "Rejeter",
    validate: "Valider",
  },
});
