import LocalizedStrings from "react-localization";

export const mappingKeysStrings = new LocalizedStrings({
  en: {
    title: "Mapping",
    resetFiltersButton: "Reset",
    mediaType: "Media Type",
    category: "Category",
    media: "Media",
    advertiser: "Advertiser",
    product: "Product",
  },
  fr: {
    title: "Mapping",
    resetFiltersButton: "Réinitialiser",
    mediaType: "Type de Média",
    category: "Catégorie",
    media: "Média",
    advertiser: "Annonceur",
    product: "Produit",
  },
});
