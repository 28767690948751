import { memo, forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TableSortLabel,
  Box,
} from "@mui/material/";
import { LogLevel, TableVirtuoso } from "react-virtuoso";

import { visuallyHidden } from "@mui/utils";
import { DataTablePagination } from "./DataTablePagination";
import { initLocalization, isDevelopment } from "../../../utils/helpers";
import { dataTableStrings } from "./locale";
import { TABLE_ROWS_PER_PAGE } from "../../../constants";

const TableComponents = {
  Scroller: forwardRef(({ style, ...props }, ref) => (
    <TableContainer
      {...props}
      ref={ref}
      sx={{ position: "relative", height: 400, flexGrow: 1 }}
    />
  )),
  Table: ({ context: { tableMinWidth }, style, ...props }) => (
    <Table
      {...props}
      className="data-table"
      size="small"
      stickyHeader
      sx={{ minWidth: tableMinWidth + "px" }}
    />
  ),
  TableHead: TableHead,
  EmptyPlaceholder: ({
    context: { showNoDataMessage, showCheckboxes, columns },
  }) =>
    showNoDataMessage && (
      <TableBody>
        <TableRow className="data-table__row">
          {showCheckboxes && <TableCell></TableCell>}
          <TableCell colSpan={columns?.length - 1}>
            {dataTableStrings.noData}
          </TableCell>
        </TableRow>
      </TableBody>
    ),
  TableRow: ({
    context: {
      isSelected,
      allowSelection,
      isLockedKeys,
      isLockedByUserKey,
      onSelectRow,
    },
    item,
    ...props
  }) => {
    const index = props["data-index"];
    const isItemSelected = isSelected(item.id);
    const isLocked = isLockedKeys.find((key) => item[key]);
    const isLockedByUser = item[isLockedByUserKey];

    return (
      <TableRow
        {...props}
        className={`data-table__row ${
          isLocked || isLockedByUser ? "data-table__row--disabled" : ""
        } ${isLocked && !isLockedByUser ? "data-table__row--locked" : ""}`}
        hover={allowSelection}
        {...(allowSelection && {
          onClick: (event) => onSelectRow(event, index),
        })}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={`${item.id}-${index}`}
        selected={isItemSelected}
        sx={allowSelection ? { cursor: "pointer" } : {}}
      />
    );
  },
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

export const DataTable = memo(function DataTable({
  columns = [],
  rows = [],
  selectedRows = [],
  showSelectAll = false,
  showCheckboxes = false,
  allowSelection = true,
  showPagination = true,
  showNoDataMessage = true,
  tableMinWidth = 600,
  page = 0,
  rowsPerPage = TABLE_ROWS_PER_PAGE[0],
  totalRecords = 0,
  sortBy = "",
  sortDirection = "asc",
  sortableColumns = [],
  isLockedKeys = [],
  isLockedByUserKey = "",
  onSelectRow = () => {},
  onSelectAllRows = () => {},
  onChangePage = () => {},
  onChangeRowsPerPage = () => {},
  onSort = () => {},
}) {
  initLocalization(dataTableStrings);

  const ref = useRef(null);

  const isSelected = (rowId) => selectedRows.includes(rowId);

  const handleSortClick = (columnId) => {
    const direction =
      sortBy === columnId && sortDirection === "asc" ? "desc" : "asc";

    onSort(columnId, direction);
    ref.current.scrollTo({ top: 0 });
  };

  const handlePageChange = (event, page) => {
    onChangePage(event, page);
    ref.current.scrollTo({ top: 0 });
  };

  const handleRowsPerPageChange = (event) => {
    onChangeRowsPerPage(event);
    ref.current.scrollTo({ top: 0 });
  };

  return (
    <>
      <TableVirtuoso
        ref={ref}
        context={{
          isSelected,
          columns,
          showCheckboxes,
          showNoDataMessage,
          allowSelection,
          tableMinWidth,
          isLockedKeys,
          isLockedByUserKey,
          onSelectRow,
        }}
        data={rows}
        components={TableComponents}
        fixedHeaderContent={() => (
          <TableRow className="data-table__row">
            {showCheckboxes && (
              <TableCell
                className="data-table__header-cell data-table__header-cell--checkbox"
                padding="checkbox"
                style={{ width: 50 }}
              >
                {showSelectAll && (
                  <Checkbox
                    color="primary"
                    onClick={onSelectAllRows}
                    disabled={!allowSelection}
                  />
                )}
              </TableCell>
            )}
            {columns.map(
              (column) =>
                (column.visible || column.visible === undefined) && (
                  <TableCell
                    key={column.field}
                    className="data-table__header-cell"
                    sortDirection={
                      sortBy === column.field ? sortDirection : false
                    }
                    style={{
                      width: column.minWidth,
                    }}
                  >
                    {sortableColumns.includes(column.field) ? (
                      <TableSortLabel
                        active={sortBy === column.field}
                        direction={
                          sortBy === column.field ? sortDirection : "asc"
                        }
                        onClick={() => handleSortClick(column.field)}
                      >
                        {column.headerName}
                        {sortBy === column.field ? (
                          <Box component="span" sx={visuallyHidden}>
                            {sortDirection === "asc"
                              ? dataTableStrings.sortedAsc
                              : dataTableStrings.sortedDesc}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      column.headerName
                    )}
                  </TableCell>
                ),
            )}
          </TableRow>
        )}
        itemContent={(index, row) => {
          const isItemSelected = isSelected(row.id);
          const isLocked = isLockedKeys.find((key) => row[key]);

          return (
            <>
              {showCheckboxes && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      "aria-labelledby": index,
                    }}
                    disabled={isLocked || !allowSelection}
                  />
                </TableCell>
              )}
              {columns.map((column) => {
                const value = row[column.field];
                return (
                  <TableCell key={column.field}>
                    {column.format ? column.format(value, row) : value}
                  </TableCell>
                );
              })}
            </>
          );
        }}
        {...(isDevelopment && { logLevel: LogLevel.DEBUG })}
      />

      {showPagination && (
        <DataTablePagination
          page={page}
          rowsPerPage={rowsPerPage}
          totalRecords={totalRecords}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      )}
    </>
  );
});

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRows: PropTypes.array,
  showSelectAll: PropTypes.bool,
  showCheckboxes: PropTypes.bool,
  allowSelection: PropTypes.bool,
  showPagination: PropTypes.bool,
  showNoDataMessage: PropTypes.bool,
  page: PropTypes.number,
  rowsPerPage: PropTypes.oneOf(TABLE_ROWS_PER_PAGE),
  totalRecords: PropTypes.number,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(["asc", "desc"]),
  sortableColumns: PropTypes.arrayOf(PropTypes.string),
  isLockedKeys: PropTypes.arrayOf(PropTypes.string),
  isLockedByUserKey: PropTypes.string,
  onSelectRow: PropTypes.func.isRequired,
  onSelectAllRows: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onSort: PropTypes.func,
};
