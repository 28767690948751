import LocalizedStrings from "react-localization";

export const updateCreativeStrings = new LocalizedStrings({
  en: {
    description: "Description",
    prevButton: "Prev",
    nextButton: "Next",
    paperClipLabel: "Enter Paper Clip label",
    paperClip: "Paper clip",
    reject: "Reject",
    validate: "Validate",
  },
  fr: {
    description: "Description",
    prevButton: "Préc",
    nextButton: "Suiv",
    paperClipLabel: "L'étiquette du Trombone",
    paperClip: "Trombone",
    reject: "Rejeter",
    validate: "Valider",
  },
});
