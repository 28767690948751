import { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../../pages/root/Root";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Paper,
  Typography,
  Backdrop,
  IconButton,
  CircularProgress,
  Button,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { Close as CloseIcon, Refresh } from "@mui/icons-material";

import { AppAlert } from "../appAlert/AppAlert";
import { DataTable } from "../dataTable/DataTable";
import { CreativeReviewFilters } from "../creativeReviewFilters/CreativeReviewFilters";
import { UpdateCreative } from "../updateCreative/UpdateCreative";

import { initLocalization } from "../../../utils/helpers";
import { creativeReviewStrings } from "./locale";
import {
  DEFAULT_CREATIVE_REVIEW_FILTERS,
  DEFAULT_CREATIVE_REVIEW_SORTING,
  MAPPING_KEYS_STATUSES,
} from "../../../constants";

export const CreativeReview = ({ dataSource, useReviewCreativeOutput }) => {
  initLocalization(creativeReviewStrings);

  const { language } = useContext(AppContext);

  const [error, setError] = useState("");
  const [filtersVersion, setFiltersVersion] = useState([]);
  const [filters, setFilters] = useState(DEFAULT_CREATIVE_REVIEW_FILTERS);
  const [sorting, setSorting] = useState(DEFAULT_CREATIVE_REVIEW_SORTING);
  const [openModal, setOpenModal] = useState(false);

  const {
    isLoading,
    creatives,
    getCreatives,
    setCreatives,
    creativeDetails,
    getCreativeDetails,
  } = useReviewCreativeOutput;

  const validatedColumns = [
    {
      field: "productId",
      headerName: creativeReviewStrings.productId,
      minWidth: 150,
    },
    {
      field: "product",
      headerName: creativeReviewStrings.product,
    },
    {
      field: "advertiser",
      headerName: creativeReviewStrings.advertiser,
    },
    {
      field: "groupId",
      headerName: creativeReviewStrings.groupId,
      minWidth: 150,
    },
    {
      field: "group",
      headerName: creativeReviewStrings.group,
    },
    {
      field: "creativesCount",
      headerName: creativeReviewStrings.creativesCount,
      minWidth: 150,
    },
  ];

  const waitingProductColumns = [
    {
      field: "paperclipLabel",
      headerName: creativeReviewStrings.paperclipLabel,
    },
    {
      field: "creativesCount",
      headerName: creativeReviewStrings.creativesCount,
      minWidth: 150,
    },
  ];

  const columns = useMemo(() => {
    if (filters.status === MAPPING_KEYS_STATUSES.validated) {
      return validatedColumns;
    }

    if (
      filters.status === MAPPING_KEYS_STATUSES.waitingProductCreated ||
      filters.status === MAPPING_KEYS_STATUSES.waitingProductRequested
    ) {
      return waitingProductColumns;
    }

    return [];
  }, [filters.status, language]);

  useEffect(() => {
    if (creatives.length) {
      setCreatives([]);
    }
    setSorting(() => {
      const next = { ...DEFAULT_CREATIVE_REVIEW_SORTING };
      next.sortOption.sortColumn = columns?.[0]?.field;
      return next;
    });
  }, [filters.status]);

  const handleCloseError = () => setError(false);

  const handleResetFilters = () => {
    setFilters(DEFAULT_CREATIVE_REVIEW_FILTERS);
    setFiltersVersion((prev) => prev + 1);
    setCreatives([]);
  };

  const handleSearch = () => {
    if (filters.status === MAPPING_KEYS_STATUSES.rejected) {
      return getCreativeDetails({
        ...filters,
        selectedProductId: "",
        selectedPaperclipLabel: "",
      })
        .then((res) => {
          if (!res?.length) {
            setError("NoResults");
            return;
          }
          setOpenModal(true);
        })
        .catch((error) => {
          setError(error);
        });
    }

    setCreatives([]);
    getCreatives({ ...filters }).catch((error) => {
      setError(error);
    });
  };

  const handleSelectRow = (e, index) => {
    getCreativeDetails({
      ...filters,
      selectedProductId: creatives[index]?.productId,
      selectedPaperclipLabel: creatives[index]?.paperclipLabel,
    })
      .then(() => {
        setOpenModal(true);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleSort = (columnId, direction) => {
    if (!creatives.length) {
      return;
    }

    const sortColumn = columnId.charAt(0).toUpperCase() + columnId.slice(1);

    getCreatives({ ...filters, sortOption: { sortColumn, direction } })
      .then(() => {
        setSorting({ ...sorting, sortOption: { sortColumn, direction } });
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    if (filters.status !== MAPPING_KEYS_STATUSES.rejected) {
      getCreatives({ ...filters }).catch((error) => {
        setError(error);
      });
    }
  };

  return (
    <Paper sx={{ padding: "0.5rem", height: "100%", position: "relative" }}>
      <AppAlert
        key={error}
        open={Boolean(error)}
        type="error"
        onClose={handleCloseError}
      >
        {creativeReviewStrings.errors[error]}
      </AppAlert>

      <Grid container direction="column" height="100%">
        <Grid xs={12} container justifyContent="space-between" minHeight="32px">
          <Typography>{creativeReviewStrings.title}</Typography>
          <Grid container>
            <Button
              sx={{ ml: 2 }}
              onClick={handleResetFilters}
              startIcon={<Refresh sx={{ transform: "scaleX(-1)" }} />}
              variant="text"
            >
              {creativeReviewStrings.resetFiltersButton}
            </Button>
          </Grid>
        </Grid>

        <Grid xs={12} paddingY={3}>
          <CreativeReviewFilters
            key={filtersVersion}
            filters={filters}
            setFilters={setFilters}
            onSearch={handleSearch}
          />
        </Grid>

        <Grid xs={12} display="flex" flexDirection="column" flexGrow="1">
          <DataTable
            columns={columns}
            rows={creatives}
            showPagination={false}
            allowSelection={true}
            showNoDataMessage={false}
            onSelectRow={handleSelectRow}
            sortBy={
              sorting?.sortOption?.sortColumn
                ? sorting.sortOption.sortColumn.charAt(0).toLowerCase() +
                  sorting.sortOption.sortColumn.slice(1)
                : ""
            }
            sortDirection={sorting?.sortOption?.direction}
            sortableColumns={columns.map((item) => item.field)}
            onSort={handleSort}
          />
        </Grid>
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-describedby="view-creatives-modal"
        sx={{
          "&": { zIndex: (theme) => theme.zIndex.drawer - 1, top: 50 },
          "& .MuiModal-backdrop": { top: 50 },
        }}
      >
        <Grid
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "calc(100vh - 50px)",
            bgcolor: "#fff",
          }}
          className="view-creatives-modal"
          id="view-creatives-modal"
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon color="primary" sx={{ width: 20, height: 20 }} />
          </IconButton>
          <UpdateCreative
            dataSource={dataSource}
            creativeDetailsData={creativeDetails}
            onClose={handleCloseModal}
          />
        </Grid>
      </Modal>

      <Backdrop
        open={isLoading}
        sx={{
          position: "absolute",
          color: "#fff",
          borderRadius: 1,
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

CreativeReview.propTypes = {
  dataSource: PropTypes.string.isRequired,
  useReviewCreativeOutput: PropTypes.object.isRequired,
};
