import LocalizedStrings from "react-localization";

export const mappingFilterModalStrings = new LocalizedStrings({
  en: {
    filterButton: "Filter",
    title: "Filters",
    productId: "Product ID",
    advertiserId: "Advertiser ID",
    socialAccountName: "Social account name",
    socialAccountFriendlyName: "Social account friendly name",
    threshold: "Threshold",
    thresholdNone: "Everything",
    belowThreshold: "Below Threshold",
    aboveThreshold: "Above Threshold",
    cancel: "Cancel",
    applyFilters: "Apply filters",
  },
  fr: {
    filterButton: "Filtrer",
    title: "Filtres",
    productId: "ID produit",
    advertiserId: "ID annonceur",
    socialAccountName: "SocialAccountName",
    socialAccountFriendlyName: "SocialAccountFriendlyName",
    threshold: "Seuil d'investissement ",
    thresholdNone: "Tout",
    belowThreshold: "En dessous du seuil",
    aboveThreshold: "Au-dessus du seuil",
    cancel: "Annuler",
    applyFilters: "Appliquer les filtres",
  },
});
