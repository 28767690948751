import LocalizedStrings from "react-localization";

export const advertiserListingFiltersStrings = new LocalizedStrings({
  en: {
    search: "Search",
    inputs: {
      advertiser: {
        id: "Advertiser ID",
        label: "Advertiser Label",
      },
    },
  },
  fr: {
    search: "Recherche",
    inputs: {
      advertiser: {
        id: "ID annonceur",
        label: "Libellé annonceur",
      },
    },
  },
});
