import { useContext, useState, useEffect } from "react";
import { useBeforeUnload } from "react-router-dom";
import { AppContext } from "../../root/Root";
import { DefaultLayout } from "../../../layouts/DefaultLayout";
import Grid from "@mui/material/Unstable_Grid2";
import {
  AttachFileOutlined,
  BlockOutlined,
  BookmarkBorderOutlined,
  FingerprintOutlined,
  BeenhereOutlined,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { actionButtonsStrings } from "./locale";
import { productListingStrings } from "../../../components/bis/productListing/locale";
import { AppAlert } from "../../../components/common/appAlert/AppAlert";
import { MappingKeys } from "../../../components/bis/mappingKeys/MappingKeys";
import { ProductListing } from "../../../components/bis/productListing/ProductListing";
import useMappingKeyStatus from "../../../hooks/common/useMappingKeyStatus";
import useMappingKeys from "../../../hooks/bis/useMappingKeys";

import {
  ACTIVATION_FILTER_OPTIONS,
  MAPPING_KEYS_STATUSES,
  MAPPING_OPTIONS,
} from "../../../constants";

import { initLocalization } from "../../../utils/helpers";
import "../../../style/common.scss";

export const Level1 = () => {
  useContext(AppContext);

  initLocalization(actionButtonsStrings);

  const { lockMappingLines } = useMappingKeys();

  const [selectedMappingKeys, setSelectedMappingKeys] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [disableAllButtons, setDisableAllButtons] = useState(false);
  const [error, setError] = useState(null);
  const {
    isLoading: actionLoading,
    paperClipMappingKey,
    rejectBisMappingKey,
    mapToCampaignMappingKey,
    manualDomainMappingKey,
    unMapMappingKey,
    success: validateSuccess,
  } = useMappingKeyStatus(MAPPING_OPTIONS.bis);

  let mappingKey = {};

  if (selectedMappingKeys?.length === 1) {
    mappingKey = {
      id: selectedMappingKeys[0]?.id,
      productId: selectedMappingKeys[0]?.productDetails?.sourceProductId,
    };
  }

  const selectedRejectMappingKeys = selectedMappingKeys.filter(
    (mappingKey) => mappingKey.status === MAPPING_KEYS_STATUSES.reject,
  );

  const selectedAutoDomainMappingKeys = selectedMappingKeys.filter(
    (mappingKey) =>
      mappingKey.status === MAPPING_KEYS_STATUSES.autoDomainMapped,
  );

  const selectedManualDomainMappingKeys = selectedMappingKeys.filter(
    (mappingKey) =>
      mappingKey.status === MAPPING_KEYS_STATUSES.manualDomainMapped,
  );

  const selectedProductRequestedMappingKeys = selectedMappingKeys.filter(
    (mappingKey) =>
      mappingKey.status === MAPPING_KEYS_STATUSES.waitingProductRequested,
  );

  const selectedProductCreatedMappingKeys = selectedMappingKeys.filter(
    (mappingKey) =>
      mappingKey.status === MAPPING_KEYS_STATUSES.waitingProductCreated,
  );

  const disableMapCampaignButton = () => {
    return (
      !selectedMappingKeys?.length ||
      !selectedProduct.id ||
      Boolean(selectedManualDomainMappingKeys?.length) ||
      Boolean(selectedProductRequestedMappingKeys?.length) ||
      actionLoading ||
      disableAllButtons
    );
  };

  const disablePaperClipButton = () => {
    return (
      !selectedMappingKeys?.length ||
      Boolean(selectedManualDomainMappingKeys?.length) ||
      Boolean(selectedProductRequestedMappingKeys?.length) ||
      Boolean(selectedProductCreatedMappingKeys?.length) ||
      actionLoading ||
      disableAllButtons
    );
  };

  const disableRejectButton = () => {
    return (
      !selectedMappingKeys?.length ||
      Boolean(selectedRejectMappingKeys?.length) ||
      Boolean(selectedManualDomainMappingKeys?.length) ||
      Boolean(selectedProductRequestedMappingKeys?.length) ||
      actionLoading ||
      disableAllButtons
    );
  };

  const disableMapDomainButton = () => {
    return (
      !selectedMappingKeys?.length ||
      !selectedProduct.id ||
      Boolean(selectedAutoDomainMappingKeys?.length) ||
      Boolean(selectedProductRequestedMappingKeys?.length) ||
      Boolean(selectedProductCreatedMappingKeys?.length) ||
      actionLoading ||
      disableAllButtons
    );
  };

  const disableDomainUnmapButton = () => {
    return (
      !selectedManualDomainMappingKeys?.length ||
      actionLoading ||
      disableAllButtons
    );
  };

  const buildPayload = (selectedMappingKeys, selectedProductId) => {
    const payload = {
      lineIds: selectedMappingKeys.map((row) => row.id),
    };

    if (selectedProductId) payload.productId = selectedProductId;

    return payload;
  };

  const handleRejectMappingKeys = () => {
    const data = buildPayload(selectedMappingKeys);
    rejectBisMappingKey(data).catch((err) => setError(err));
  };

  const handlePaperClipMappingKeys = () => {
    const data = buildPayload(selectedMappingKeys);
    paperClipMappingKey(data).catch((err) => setError(err));
  };

  const handleMapCampaignMappingKey = () => {
    const data = buildPayload(selectedMappingKeys, selectedProduct.id);
    mapToCampaignMappingKey(data).catch((err) => setError(err));
  };

  const handleMapDomainMappingKey = () => {
    const data = buildPayload(selectedMappingKeys, selectedProduct.id);
    manualDomainMappingKey(data).catch((err) => setError(err));
  };

  const handleUnMapDomainMappingKey = () => {
    const data = buildPayload(selectedMappingKeys);
    unMapMappingKey(data).catch((err) => setError(err));
  };

  const handleCloseError = () => setError(false);

  // Unlock mapping lines on unmount and page unload
  useEffect(() => {
    return () => {
      lockMappingLines({
        shouldLock: false,
      });
    };
  }, []);

  useBeforeUnload(() => {
    lockMappingLines({
      shouldLock: false,
    });
  });

  return (
    <DefaultLayout>
      <AppAlert
        key={error}
        open={Boolean(error)}
        type="error"
        onClose={handleCloseError}
      >
        {productListingStrings.errors[error]}
      </AppAlert>
      <Grid
        className="page-content"
        container
        rowSpacing={{ xs: 3, md: 0 }}
        columnSpacing={{ xs: 0, md: 2 }}
        padding={{ xs: 1, md: 2 }}
        disableEqualOverflow
      >
        <Grid md={7}>
          <MappingKeys
            shouldRefresh={validateSuccess}
            onMappingKeysSelection={setSelectedMappingKeys}
            onDisableAllButtons={setDisableAllButtons}
          />
        </Grid>
        <Grid md={5}>
          <Grid container direction="column" columns={12} height="100%">
            <Grid
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap={2}
              smOffset="auto"
              paddingY={3}
            >
              <Button
                startIcon={<FingerprintOutlined />}
                variant="outlined"
                size="large"
                disabled={disableDomainUnmapButton()}
                onClick={handleUnMapDomainMappingKey}
              >
                {actionButtonsStrings.unMapDomain}
              </Button>
              <Button
                startIcon={<BeenhereOutlined />}
                variant="outlined"
                color="success"
                size="large"
                disabled={disableMapDomainButton()}
                onClick={handleMapDomainMappingKey}
              >
                {actionButtonsStrings.mapDomain}
              </Button>
            </Grid>
            <Grid xs={12} flexGrow="1">
              <ProductListing
                activationFilterOption={ACTIVATION_FILTER_OPTIONS.bisCampaigns}
                mappingKey={mappingKey}
                allowSearch={true}
                onSelectedProductRow={setSelectedProduct}
                shouldRefresh={validateSuccess}
                isLoading={actionLoading}
              />
            </Grid>
            <Grid
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap={2}
              smOffset="auto"
              paddingY={3}
            >
              <Button
                startIcon={<AttachFileOutlined />}
                variant="outlined"
                size="large"
                disabled={disablePaperClipButton()}
                onClick={handlePaperClipMappingKeys}
              >
                {actionButtonsStrings.paperClip}
              </Button>
              <Button
                startIcon={<BlockOutlined />}
                variant="outlined"
                color="error"
                size="large"
                onClick={handleRejectMappingKeys}
                disabled={disableRejectButton()}
              >
                {actionButtonsStrings.reject}
              </Button>
              <Button
                startIcon={<BookmarkBorderOutlined />}
                variant="outlined"
                color="success"
                size="large"
                disabled={disableMapCampaignButton()}
                onClick={handleMapCampaignMappingKey}
              >
                {actionButtonsStrings.mapCampaign}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};
