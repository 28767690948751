import LocalizedStrings from "react-localization";

export const languageMenuStrings = new LocalizedStrings({
  en: {
    changeLanguage: "Change the language",
  },
  fr: {
    changeLanguage: "Modifier la langue",
  },
});
