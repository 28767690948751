import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_BIS_POOL_SIZE } from "../../routes";
import { debug } from "../../utils/helpers";
import { DEFAULT_BIS_POOL_SIZE_PAYLOAD } from "../../constants";

export function usePoolSize(auto = false) {
  const [isLoading, setIsLoading] = useState(false);
  const [poolData, setPoolData] = useState([]);
  const [totalPoolSize, setTotalPoolSize] = useState(0);

  const getPoolData = (payload = {}, showLoader = true) => {
    setIsLoading(showLoader);

    return Api.post(API_ROUTE_BIS_POOL_SIZE, {
      ...DEFAULT_BIS_POOL_SIZE_PAYLOAD,
      ...payload,
    })
      .then((response) => {
        setPoolData(response.data.data);
        setTotalPoolSize(response.data.totalRecords);
      })
      .catch((error) => {
        debug.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!auto) {
      return;
    }
    getPoolData();
  }, [auto]);

  return {
    isLoading,
    totalPoolSize,
    setTotalPoolSize,
    poolData,
    setPoolData,
    getPoolData,
  };
}
