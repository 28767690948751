import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import {
  API_ROUTE_BIS_MAPPING_KEYS,
  API_ROUTE_BIS_MAPPING_KEYS_LOCKED_LINES,
} from "../../routes";
import { debug } from "../../utils/helpers";
import { DEFAULT_BIS_MAPPING_KEYS_PAYLOAD } from "../../constants";

export default function useMappingKeys(auto = false) {
  const [isLoading, setIsLoading] = useState(false);
  const [mappingKeys, setMappingKeys] = useState([]);

  const getMappingKeys = (payload = {}, showLoader = true) => {
    setIsLoading(showLoader);

    Api.post(API_ROUTE_BIS_MAPPING_KEYS, {
      ...DEFAULT_BIS_MAPPING_KEYS_PAYLOAD,
      ...payload,
    })
      .then((response) => {
        setMappingKeys(response.data);
      })
      .catch((error) => {
        debug.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!auto) {
      return;
    }
    getMappingKeys();
  }, [auto]);

  const lockMappingLines = (payload, showLoader = false) => {
    setIsLoading(showLoader);

    return Api.patch(API_ROUTE_BIS_MAPPING_KEYS_LOCKED_LINES, payload)
      .catch((error) => Promise.reject(error?.response?.data?.errors?.LineIds))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    mappingKeys,
    getMappingKeys,
    setMappingKeys,
    lockMappingLines,
  };
}
