import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { Paper } from "@mui/material";
import { initLocalization } from "../../../utils/helpers";
import { creativeClassificationStrings } from "./locale";

import { UserPoolThumbnails } from "../userPoolThumbnails/UserPoolThumbnails";
import { PoolMedia } from "../poolMedia/PoolMedia";
import { PoolMetadata as SocialAccountsMetadata } from "../../pathmatics/poolMetadata/PoolMetadata";
import { PoolMetadata as BisMetadata } from "../../bis/poolMetadata/PoolMetadata";
import { DATA_SOURCE } from "../../../constants";

export const CreativeClassification = ({
  dataSource,
  poolData,
  totalPoolSize,
  isLoading,
  selectedProduct,
  selectedCreatives,
  resetSelectedCreatives,
  onCreativesSelection,
  onResetSelectedCreatives,
}) => {
  initLocalization(creativeClassificationStrings);

  let showCreative = false;

  if (selectedCreatives.length === 1 && resetSelectedCreatives === false) {
    showCreative = true;
  } else {
    showCreative = false;
  }

  return (
    <Paper sx={{ padding: "0.5rem", height: "100%", position: "relative" }}>
      <Grid
        container
        gap="1rem"
        height="100%"
        width="100%"
        direction="row"
        flexWrap="nowrap"
      >
        <Grid flex="0 1 25%">
          <UserPoolThumbnails
            poolData={poolData}
            totalPoolSize={totalPoolSize}
            isLoading={isLoading}
            resetSelectedCreatives={resetSelectedCreatives}
            onCreativesSelection={onCreativesSelection}
            onResetCreativesSelection={onResetSelectedCreatives}
          />
        </Grid>
        <Grid flex="0 1 75%">
          {showCreative &&
            selectedCreatives.map((creative) => (
              <Grid key={creative.id}>
                <PoolMedia creativeDetails={creative} />
                {dataSource === DATA_SOURCE.bis && (
                  <BisMetadata
                    creativeDetails={creative}
                    selectedProduct={selectedProduct}
                  />
                )}
                {dataSource === DATA_SOURCE.pathmatics && (
                  <SocialAccountsMetadata creativeDetails={creative} />
                )}
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

CreativeClassification.propTypes = {
  dataSource: PropTypes.string.isRequired,
  poolData: PropTypes.array.isRequired,
  totalPoolSize: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedProduct: PropTypes.object,
  selectedCreatives: PropTypes.array.isRequired,
  resetSelectedCreatives: PropTypes.bool.isRequired,
  onCreativesSelection: PropTypes.func.isRequired,
  onResetSelectedCreatives: PropTypes.func.isRequired,
};
