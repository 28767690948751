export const SESSION_REFRESH_INTERVAL = 10; // minutes

export const AUTH_ERROR_QUERY_STRING_KEY = "message";

export const CREATIVES_MEDIA_DOMAIN = "https://km-adexpress.kantarmedia.fr";

export const LANGUAGES = {
  en: "en",
  fr: "fr",
};

export const ROLES = {
  admin: "Administrator",
  operator: "Operator",
};

export const DATA_SOURCE = {
  pathmatics: "Pathmatics",
  bis: "BIS",
};

export const EXCLUDED_NUMERIC_FIELD_CHARS = [",", ".", "-", "+", "e", "E"];

export const NUMERIC_INPUT_LIMIT = 300;

export const MAPPING_FILTER_DATEPICKER_START_DATE = {
  default: "2023-01-01",
  facebook: "2019-01-01",
};

export const MAPPING_KEYS_DATE_FORMAT = "DD/MM/YYYY";
export const MAPPING_KEYS_API_DATE_FORMAT = "YYYY-MM-DD";

export const TABLE_ROWS_PER_PAGE = [25, 50, 75, 100];

// Table page number must be a zero-based index value
export const DEFAULT_TABLE_PAGINATION = {
  pageNumber: 0,
  pageSize: TABLE_ROWS_PER_PAGE[0],
};

// mapping keys date filters
export const START_DATE = "startDate";
export const END_DATE = "endDate";

export const DEFAULT_MAPPING_KEYS_FILTERS = {
  [START_DATE]: null,
  [END_DATE]: null,
  mediaChannelIds: [],
  mappingStatus: "",
  threshold: "None",
  socialAccountName: "",
  socialAccountFriendlyName: "",
  productId: "",
  advertiserId: "",
};

export const DEFAULT_MAPPING_KEYS_SORTING = {
  sortOption: {
    sortColumn: "SocialAccountName",
    direction: "asc",
  },
};

// Payload page number must be a one-based index value
export const DEFAULT_MAPPING_KEYS_PAYLOAD = {
  ...DEFAULT_TABLE_PAGINATION,
  pageNumber: 1,
  ...DEFAULT_MAPPING_KEYS_FILTERS,
  ...DEFAULT_MAPPING_KEYS_SORTING,
};

export const MEDIA_CHANNELS = {
  facebook: "Facebook",
  instagram: "Instagram",
  snapchat: "Snapchat",
  tiktok: "TikTok",
};

// default time after lines are automatically unlocked
export const DEFAULT_COUNTDOWN_TIMER = 30 * 60;
export const DEFAULT_COUNTDOWN_INTERVAL = 1000; // ex: 1000 (1s), 500 (ms), etc...

export const SEARCH_FIELD_MIN_CHARS = 3;

export const ADVERTISER_ID = "advertiserId";
export const ADVERTISER_LABEL = "advertiserLabel";
export const PRODUCT_ID = "productId";
export const SOURCE_PRODUCT_ID = "sourceProductId";
export const PRODUCT_LABEL = "productLabel";

export const DEFAULT_PRODUCT_LISTING_FILTERS = {
  [ADVERTISER_ID]: "",
  [ADVERTISER_LABEL]: "",
  [SOURCE_PRODUCT_ID]: "",
  [PRODUCT_LABEL]: "",
  includeSocialAccounts: true,
};

export const ACTIVATION_FILTER_OPTIONS = {
  default: "Default",
  creatives: "Creatives",
  bisCampaigns: "BisCampaigns",
  selfPromotion: "SelfPromotion",
};

export const MAPPING_OPTIONS = {
  bis: "BisCampaign",
  bisCreative: "BisCreative",
  pathmatics: "SocialAccount",
  pathmaticsCreative: "SocialAccountCreative",
};

export const MAPPING_KEYS_STATUSES = {
  validated: "Validated",
  rejected: "Rejected",
  reject: "Reject",
  auto: "Auto",
  manual: "Manual",
  manualDomainMapped: "M/Domain Mapped",
  autoDomainMapped: "A/Domain Mapped",
  waitingProductRequested: "Waiting, product requested",
  waitingProductCreated: "Waiting, product created",
  notMapped: "Not mapped",
};

export const PATHMATICS_POOL_SIZES_ARRAY = [10, 50, 100];

export const DEFAULT_PATHMATICS_POOL_SIZE_PAYLOAD = {
  currentPoolSize: 10,
  previousPoolSize: 10,
  productId: null,
  nextPool: false,
};

export const DEFAULT_BIS_MAPPING_KEYS_FILTERS = {
  [START_DATE]: null,
  [END_DATE]: null,
  status: "",
  keywordSearchField: "all",
  keyword: "",
};

export const DEFAULT_BIS_MAPPING_KEYS_SORTING = {
  sortOption: {
    sortColumn: "FirstOccurrence",
    direction: "asc",
  },
};

export const DEFAULT_BIS_MAPPING_KEYS_PAYLOAD = {
  ...DEFAULT_TABLE_PAGINATION,
  pageNumber: 1,
  ...DEFAULT_BIS_MAPPING_KEYS_FILTERS,
};

export const DEFAULT_BIS_PRODUCT_LISTING_FILTERS = {
  [ADVERTISER_ID]: "",
  [ADVERTISER_LABEL]: "",
  [SOURCE_PRODUCT_ID]: "",
  [PRODUCT_LABEL]: "",
  includeSocialAccounts: false,
  sortOption: {
    sortColumn: "ProductName",
    direction: "asc",
  },
};

export const BIS_POOL_SIZES_ARRAY = [10, 50, 100];

export const DEFAULT_BIS_POOL_SIZE_PAYLOAD = {
  currentPoolSize: 10,
  previousPoolSize: 10,
  productId: null,
  nextPool: false,
};

export const DEFAULT_CREATIVE_REVIEW_FILTERS = {
  creativeId: "",
  creativeHashCode: "",
  product: "",
  startDate: null,
  endDate: null,
  status: "",
};

export const DEFAULT_CREATIVE_REVIEW_SORTING = {
  sortOption: {
    sortColumn: "",
    direction: "asc",
  },
};

export const DEFAULT_MAPPINGS_VIEW_FILTERS = {
  mediaType: "",
  category: "",
  media: "",
  advertiser: "",
  product: "",
};

export const DEFAULT_MAPPINGS_VIEW_SORTING = {
  sortOption: {
    sortColumn: "Media",
    direction: "asc",
  },
};

export const DEFAULT_MAPPINGS_VIEW_PAYLOAD = {
  ...DEFAULT_TABLE_PAGINATION,
  pageNumber: 1,
  ...DEFAULT_MAPPINGS_VIEW_FILTERS,
  ...DEFAULT_MAPPINGS_VIEW_SORTING,
};

export const PREDEFINED_FILTER = {
  selfPromotion: "self-promotion",
};

export const DEFAULT_MEDIA_LISTING_FILTERS = {
  mediaId: "",
  mediaLabel: "",
};

export const DEFAULT_ADVERTISER_LISTING_FILTERS = {
  advertiserId: "",
  advertiserLabel: "",
};

export const DEFAULT_SELF_PROMOTION_PRODUCT_LISTING_FILTERS = {
  [ADVERTISER_ID]: "",
  [ADVERTISER_LABEL]: "",
  [SOURCE_PRODUCT_ID]: "",
  [PRODUCT_LABEL]: "",
  includeSocialAccounts: false,
  sortOption: {
    sortColumn: "ProductName",
    direction: "asc",
  },
};
