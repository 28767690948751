import LocalizedStrings from "react-localization";

export const advertiserListingStrings = new LocalizedStrings({
  en: {
    title: "Advertisers",
    advertiserId: "Avertiser ID",
    advertiser: "Advertiser",
    activation: "Activation",
    creationDate: "Date of creation",
  },
  fr: {
    title: "Annonceurs",
    advertiserId: "ID Annonceur",
    advertiser: "Annonceur",
    activation: "Activation",
    creationDate: "Date de création",
  },
});
