import { useState } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_MAPPING_DETAILS } from "../../routes";

export default function useViewMappingDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [mappingDetails, setMappingDetails] = useState([]);

  const getMappingDetails = (socialAccountIds) => {
    setIsLoading(true);
    setMappingDetails([]);
    return Api.post(API_ROUTE_MAPPING_DETAILS, { socialAccountIds })
      .then((response) => {
        setMappingDetails(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    mappingDetails,
    getMappingDetails,
  };
}
