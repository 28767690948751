import { createContext, useMemo, useState } from "react";
import { Navigate, Outlet, useLoaderData, useLocation } from "react-router-dom";
import { ROUTE_LOGIN, ROUTE_MAIN } from "../../routes";
import { LANGUAGES, ROLES, AUTH_ERROR_QUERY_STRING_KEY } from "../../constants";

export const AppContext = createContext(null);

export const Root = () => {
  const user = useLoaderData();
  const location = useLocation();
  const [language, setLanguage] = useState(
    localStorage.getItem("lang") ?? LANGUAGES.fr,
  );
  const hasAuthError =
    location.search.split("=")[0] === `?${AUTH_ERROR_QUERY_STRING_KEY}`;

  const contextValue = useMemo(
    () => ({
      user,
      isAdmin: user?.role === ROLES.admin,
      language,
      changeLanguage: (lang) => {
        setLanguage(lang);
        localStorage.setItem("lang", lang);
      },
    }),
    [language, user],
  );

  if (!user && location.pathname !== ROUTE_LOGIN) {
    return (
      <Navigate
        to={`${ROUTE_LOGIN}${hasAuthError ? location.search : ""}`}
        replace
      />
    );
  }

  if (user && location.pathname === ROUTE_LOGIN) {
    return <Navigate to={ROUTE_MAIN} replace />;
  }

  return (
    <AppContext.Provider value={contextValue}>
      <Outlet />
    </AppContext.Provider>
  );
};
