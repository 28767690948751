import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../../pages/root/Root";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/en";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  Paper,
  Typography,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { AppAlert } from "../../common/appAlert/AppAlert";
import {
  Close as CloseIcon,
  Refresh,
  AttachmentOutlined,
  AccountCircleOutlined,
} from "@mui/icons-material";
import Modal from "@mui/material/Modal";

import { DataTable } from "../../common/dataTable/DataTable";
import { MappingKeysTimer } from "../../common/mappingKeysTimer/MappingKeysTimer";
import { MappingFilterModal } from "../mappingFilterModal/MappingFilterModal";
import { MappingKeysFilters } from "../mappingKeysFilters/MappingKeysFilters";
import { ViewMappingDetails } from "../viewMappingDetails/ViewMappingDetails";
import { SocialPageLink } from "../socialPageLink/SocialPageLink";
import { MappingStatusColored } from "../../common/mappingStatusColored/MappingStatusColored";

import useMappingKeys from "../../../hooks/pathmatics/useMappingKeys";
import useGetMediaChannels from "../../../hooks/pathmatics/useGetMediaChannels";
import useViewMappingDetails from "../../../hooks/pathmatics/useViewMappingDetails";
import useNotificationHub from "../../../hooks/common/useNotificationHub";
import useIsMounted from "../../../hooks/common/useIsMounted";

import { initLocalization } from "../../../utils/helpers";
import { mappingKeysStrings } from "./locale";
import {
  MAPPING_KEYS_DATE_FORMAT,
  DEFAULT_TABLE_PAGINATION,
  DEFAULT_MAPPING_KEYS_SORTING,
  DEFAULT_MAPPING_KEYS_FILTERS,
} from "../../../constants";

export const MappingKeys = ({
  onMappingKeysSelection,
  onLockedMappingKeysSelection,
  shouldRefresh,
}) => {
  initLocalization(mappingKeysStrings);

  const isMounted = useIsMounted();

  const { user } = useContext(AppContext);

  const [error, setError] = useState("");
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [selectedLockedRows, setSelectedLockedRows] = useState(() => new Set());
  const lastSelected = useRef(null);
  const [pagination, setPagination] = useState(DEFAULT_TABLE_PAGINATION);
  const [filters, setFilters] = useState(DEFAULT_MAPPING_KEYS_FILTERS);
  const [sorting, setSorting] = useState(DEFAULT_MAPPING_KEYS_SORTING);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const { mediaChannels } = useGetMediaChannels(true);
  const {
    isLoading: mappingKeysLoading,
    mappingKeys,
    getMappingKeys,
    lockMappingLines,
  } = useMappingKeys(true);

  const {
    mappingDetails,
    getMappingDetails,
    isLoading: isDetailsLoading,
  } = useViewMappingDetails();
  const { message: updatedMappingKeys } = useNotificationHub();

  const [scheduleTimer, setScheduleTimer] = useState(false);

  const allSelectedRows = useMemo(
    () => [...selectedRows, ...selectedLockedRows],
    [selectedLockedRows, selectedRows],
  );

  const mappingKeysData = useMemo(() => {
    if (updatedMappingKeys?.lineIds?.length) {
      const shouldLock = updatedMappingKeys.status === "Lock";
      const isCurrentUser = updatedMappingKeys.userId === user.id;

      return mappingKeys?.data?.map((row) => {
        // Prevent unlock visual flash when multiple selecting
        if (!shouldLock && selectedRows.has(row.id)) {
          return row;
        }

        if (updatedMappingKeys.lineIds.includes(row.id)) {
          row.isLocked = shouldLock;
          row.isLockedByCurrentUser = isCurrentUser && shouldLock;
        }

        return row;
      });
    }

    return mappingKeys?.data;
  }, [mappingKeys, updatedMappingKeys]);

  const columns = [
    {
      field: "socialAccountPage",
      headerName: (
        <Grid container alignItems="center">
          <AttachmentOutlined />
        </Grid>
      ),
      minWidth: 32,
      format: (value, row) => <SocialPageLink row={row} />,
    },
    {
      field: "socialAccountName",
      headerName: mappingKeysStrings.socialAccountName,
      minWidth: 182,
    },
    {
      field: "socialAccountFriendlyName",
      headerName: mappingKeysStrings.socialAccountFriendlyName,
      minWidth: 182,
    },
    {
      field: "mappingStatus",
      headerName: mappingKeysStrings.mappingStatus,
      minWidth: 70,
      format: (value, row) => <MappingStatusColored value={value} row={row} />,
    },
    {
      field: "productDetails",
      headerName: mappingKeysStrings.product,
      minWidth: 182,
      format: (value) => (value !== null ? value.name : ""),
    },
    {
      field: "totalSpend",
      headerName: mappingKeysStrings.totalSpend,
      minWidth: 72,
      format: (value) => Intl.NumberFormat("en").format(Math.round(value)),
    },
    {
      field: "totalImpressions",
      headerName: mappingKeysStrings.totalImpressions,
      minWidth: 85,
      format: (value) => Intl.NumberFormat("en").format(value),
    },
    {
      field: "firstOccurrence",
      headerName: mappingKeysStrings.firstOccurrence,
      minWidth: 66,
      format: (value) => dayjs(value).format(MAPPING_KEYS_DATE_FORMAT),
    },
  ];

  const handleCloseError = () => setError(false);

  const handleResetFilters = () => {
    setFilters(DEFAULT_MAPPING_KEYS_FILTERS);
  };

  const getSelected = (targetState, currentIndex, allowMultiple, indices) => {
    const prevArray = [...targetState];
    const current = prevArray.find(
      (id) => id === mappingKeysData[currentIndex].id,
    );
    const currentSet =
      current && prevArray.length === 1 ? new Set([current]) : new Set();
    const next = allowMultiple ? new Set(targetState) : currentSet;

    indices.forEach((idx) => {
      const id = mappingKeysData[idx].id;
      next.has(id) ? next.delete(id) : next.add(id);
    });

    return next;
  };

  // Select row function
  const handleSelectRow = (event, index) => {
    const indices = new Set();
    const lockedIndices = new Set();
    let allowMultiple = false;

    (mappingKeysData[index].isLocked &&
    !mappingKeysData[index].isLockedByCurrentUser
      ? lockedIndices
      : indices
    ).add(index);

    if (event.shiftKey) {
      const limits = [lastSelected.current ?? 0, index].sort((a, b) => a - b);
      const [start, end] = limits;

      for (let i = start; i <= end; i++) {
        if (
          mappingKeysData[i].isLocked &&
          !mappingKeysData[i].isLockedByCurrentUser
        ) {
          lockedIndices.add(i);
          continue;
        }
        indices.add(i);
      }

      indices.delete(lastSelected.current);
      lockedIndices.delete(lastSelected.current);
      allowMultiple = true;
    } else if (event.metaKey || event.ctrlKey) {
      allowMultiple = true;
    }

    const mappingKeysDataCopy = [...mappingKeysData];
    const newSelection = getSelected(
      selectedRows,
      index,
      allowMultiple,
      indices,
    );
    const newLockedSelection = getSelected(
      selectedLockedRows,
      index,
      allowMultiple,
      lockedIndices,
    );

    setSelectedRows(newSelection);
    setSelectedLockedRows(newLockedSelection);

    onMappingKeysSelection(
      mappingKeysDataCopy.filter((entry) => newSelection.has(entry.id)),
    );
    onLockedMappingKeysSelection(
      mappingKeysDataCopy.filter((entry) => newLockedSelection.has(entry.id)),
    );

    lastSelected.current = index;
  };

  const handleModalFilters = (modalFilters) => {
    setPagination({ ...pagination, pageNumber: 0 });
    setFilters({ ...filters, ...modalFilters });
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, pageNumber: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    setPagination({ ...pagination, pageNumber: 0, pageSize: value });
  };

  const handleSort = (columnId, direction) => {
    const sortColumn = columnId.charAt(0).toUpperCase() + columnId.slice(1);
    setPagination({ ...pagination, pageNumber: 0 });
    setSorting({ ...sorting, sortOption: { sortColumn, direction } });
  };

  const handleViewCreatives = async () => {
    await getMappingDetails(allSelectedRows);
    handleOpenModal();
  };

  const updateMappingKeys = () => {
    getMappingKeys({
      ...pagination,
      pageNumber: pagination.pageNumber + 1,
      ...filters,
      ...sorting,
    });
  };

  const mappingTableChange = async () => {
    await lockMappingLines(
      {
        shouldLock: false,
      },
      true,
    );

    setSelectedRows(() => new Set());
    setSelectedLockedRows(() => new Set());
    onMappingKeysSelection([]);
    onLockedMappingKeysSelection([]);
    lastSelected.current = null;

    updateMappingKeys();
  };

  const lockLines = async (selection) => {
    try {
      setScheduleTimer(false);
      // Reset locked lines. Stop execution if unlocking fails
      await lockMappingLines({
        shouldLock: false,
      }).catch((error) => {
        throw new Error(error[0]);
      });

      if (selection.length) {
        await lockMappingLines({
          shouldLock: true,
          lineIds: selection,
        })
          .then(() => setScheduleTimer(true))
          .catch((error) => {
            setError(error[0]);
          });
      }
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    if (lastSelected.current === null) {
      return;
    }

    lockLines([...selectedRows]);
  }, [selectedRows]);

  useEffect(() => {
    if (shouldRefresh) {
      mappingTableChange();
      setScheduleTimer(false);
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (isMounted) {
      mappingTableChange();
      setScheduleTimer(false);
    }
  }, [pagination, filters, sorting]);

  return (
    <Paper sx={{ padding: "0.5rem", height: "100%", position: "relative" }}>
      <AppAlert
        key={error}
        open={Boolean(error)}
        type="error"
        onClose={handleCloseError}
      >
        {mappingKeysStrings.errors[error]}
      </AppAlert>

      <Grid container direction="column" height="100%">
        <Grid xs={12}>
          <Typography>{mappingKeysStrings.title}</Typography>
        </Grid>

        <Grid
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap={2}
          smOffset="auto"
          marginBottom={2}
          marginTop={{ xs: 2, sm: 0 }}
        >
          <MappingKeysTimer
            scheduleTimer={scheduleTimer}
            setSelectedRowsCB={() => setSelectedRows(() => new Set())}
          />

          <Button
            startIcon={<AccountCircleOutlined />}
            disabled={!allSelectedRows.length}
            variant="contained"
            color="secondary"
            onClick={handleViewCreatives}
          >
            {mappingKeysStrings.viewCreativesButton}
          </Button>

          <MappingFilterModal
            onApplyFilters={handleModalFilters}
            filters={filters}
          />

          <Button
            startIcon={<Refresh sx={{ transform: "scaleX(-1)" }} />}
            disabled={
              JSON.stringify(DEFAULT_MAPPING_KEYS_FILTERS) ===
              JSON.stringify(filters)
            }
            color="secondary"
            onClick={handleResetFilters}
          >
            {mappingKeysStrings.resetFiltersButton}
          </Button>
        </Grid>

        <Grid xs={12} paddingY={3}>
          <MappingKeysFilters
            setPagination={setPagination}
            setFilters={setFilters}
            filters={filters}
            mediaChannels={mediaChannels}
          />
        </Grid>

        <Grid xs={12} display="flex" flexDirection="column" flexGrow="1">
          <DataTable
            columns={columns}
            rows={mappingKeysData || []}
            selectedRows={allSelectedRows}
            page={pagination.pageNumber}
            rowsPerPage={pagination.pageSize}
            totalRecords={mappingKeys.totalRecords}
            sortBy={
              sorting.sortOption.sortColumn.charAt(0).toLowerCase() +
              sorting.sortOption.sortColumn.slice(1)
            }
            sortDirection={sorting.sortOption.direction}
            sortableColumns={[
              "socialAccountName",
              "socialAccountFriendlyName",
              "firstOccurrence",
              "totalSpend",
              "totalImpressions",
              "productDetails",
              "mappingStatus",
            ]}
            isLockedKeys={["isLocked"]}
            isLockedByUserKey="isLockedByCurrentUser"
            onSelectRow={handleSelectRow}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSort}
            tableMinWidth={1079}
          />
        </Grid>
      </Grid>

      <Modal
        open={openModal && !isDetailsLoading}
        onClose={handleCloseModal}
        aria-describedby="view-creatives-modal"
        sx={{
          "&": { zIndex: (theme) => theme.zIndex.drawer - 1, top: 50 },
          "& .MuiModal-backdrop": { top: 50 },
        }}
      >
        <Grid
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "calc(100vh - 50px)",
            bgcolor: "#fff",
          }}
          className="view-creatives-modal"
          id="view-creatives-modal"
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon color="primary" sx={{ width: 20, height: 20 }} />
          </IconButton>
          <ViewMappingDetails mappingDetailsData={mappingDetails} />
        </Grid>
      </Modal>

      <Backdrop
        open={mappingKeysLoading || isDetailsLoading}
        sx={{
          position: "absolute",
          color: "#fff",
          borderRadius: 1,
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

MappingKeys.propTypes = {
  shouldRefresh: PropTypes.number.isRequired,
};
