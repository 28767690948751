import LocalizedStrings from "react-localization";

export const mappingKeysStrings = new LocalizedStrings({
  en: {
    title: "Mapping keys",
    viewCreativesButton: "View creatives",
    filterButton: "Filters",
    resetFiltersButton: "Reset",
    socialAccountName: "Account name",
    socialAccountFriendlyName: "Friendly name",
    mappingStatus: "Mapping",
    product: "Product",
    firstOccurrence: "Date",
    totalSpend: "Spends",
    totalImpressions: "Impressions",
    errors: {
      FieldRequired: "There are no social accounts selected for locking.",
      LinesMissingOrLocked:
        "The social account you selected is locked or not found.",
      LockingNotAllowed: "There was an error, while locking the lines.",
      UnlockingNotAllowed: "There was an error, while unlocking the lines.",
    },
    socialAccountPage: "Social account page",
  },
  fr: {
    title: "Clés de Mapping",
    viewCreativesButton: "Voir les créations",
    filterButton: "Filtres",
    resetFiltersButton: "Réinitialiser",
    socialAccountName: "SAN",
    socialAccountFriendlyName: "SAFN",
    mappingStatus: "Mapping",
    product: "Produit",
    firstOccurrence: "Date",
    totalSpend: "Dépenses",
    totalImpressions: "Impressions",
    errors: {
      FieldRequired:
        "Aucun Social Account n'a été sélectionné pour le verrouillage.",
      LinesMissingOrLocked:
        "Le Social Account que vous avez sélectionné est verrouillé ou introuvable.",
      LockingNotAllowed:
        "Une erreur s'est produite lors du verrouillage des lignes.",
      UnlockingNotAllowed:
        "Une erreur s'est produite lors du déverrouillage des lignes.",
    },
    socialAccountPage: "Page du Social Account",
  },
});
