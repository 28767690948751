import React, { memo } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  Box,
  TextField,
  Checkbox,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { NumericInput } from "../../common/numericInput/NumericInput";
import { Search } from "@mui/icons-material";
import { initLocalization } from "../../../utils/helpers";
import { productListingFiltersStrings } from "./locale";
import {
  ADVERTISER_ID,
  ADVERTISER_LABEL,
  SOURCE_PRODUCT_ID,
  PRODUCT_LABEL,
  SEARCH_FIELD_MIN_CHARS,
  EXCLUDED_NUMERIC_FIELD_CHARS,
} from "../../../constants";

export const ProductListingFilters = memo(function ProductListingFilters({
  allowSearch,
  validVisual = false,
  filters,
  setFilters,
  onSetValidVisual,
  onIncludeSocialAccounts,
  resetProductsTable,
  getProductsData,
  showValidVisualCheckbox = false,
  showSocialAccountsCheckbox,
}) {
  initLocalization(productListingFiltersStrings);

  const handleIncludeSocialAccounts = (event) => {
    setFilters({ ...filters, includeSocialAccounts: event.target.checked });
    onIncludeSocialAccounts(disableSearch(), event.target.checked);
  };

  const handleFieldChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleFieldKeydown = (e) => {
    const idFields = [ADVERTISER_ID, SOURCE_PRODUCT_ID];

    if (
      idFields.includes(e.target.name) &&
      EXCLUDED_NUMERIC_FIELD_CHARS.includes(e.key)
    ) {
      e.preventDefault();
    }

    if (e.key === "Enter") {
      e.stopPropagation();
      handleProductsSearch();
    }
  };

  const handleProductsSearch = () => {
    if (disableSearch()) {
      return;
    }
    resetProductsTable();
    getProductsData({ ...filters });
  };

  const disableSearch = () => {
    const labelFields = [ADVERTISER_LABEL, PRODUCT_LABEL];
    const insufficientChars = labelFields.some(
      (field) =>
        filters[field] !== "" &&
        filters[field].trim().length < SEARCH_FIELD_MIN_CHARS,
    );
    const allFieldsEmpty = [
      ...labelFields,
      ADVERTISER_ID,
      SOURCE_PRODUCT_ID,
    ].every((field) => filters[field].trim() === "");

    return !allowSearch || allFieldsEmpty || insufficientChars;
  };

  return (
    <Grid container>
      <Grid xsOffset="auto" paddingBottom={2}>
        {showValidVisualCheckbox && (
          <FormControlLabel
            control={<Checkbox checked={validVisual} />}
            label={productListingFiltersStrings.validVisual}
            onChange={onSetValidVisual}
          />
        )}
        {showSocialAccountsCheckbox && (
          <FormControlLabel
            control={<Switch checked={filters.includeSocialAccounts} />}
            label={productListingFiltersStrings.includeSocialAccounts}
            sx={{ gap: 1, marginLeft: 0 }}
            onChange={handleIncludeSocialAccounts}
          />
        )}

        <Button
          startIcon={<Search />}
          variant="contained"
          color="secondary"
          onClick={handleProductsSearch}
          disabled={disableSearch()}
        >
          {productListingFiltersStrings.search}
        </Button>
      </Grid>

      <Grid
        xs={12}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
        paddingY={3}
      >
        <Box
          sx={{
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <NumericInput
            id="outlined-disabled"
            label={productListingFiltersStrings.inputs.advertiser.id}
            size="small"
            name={ADVERTISER_ID}
            value={filters[ADVERTISER_ID]}
            setValue={(val) =>
              handleFieldChange({ target: { name: ADVERTISER_ID, value: val } })
            }
            onKeyDown={handleFieldKeydown}
          />
        </Box>
        <Box
          flexGrow="1"
          sx={{
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <TextField
            id="outlined-disabled"
            label={productListingFiltersStrings.inputs.advertiser.label}
            size="small"
            name={ADVERTISER_LABEL}
            value={filters[ADVERTISER_LABEL]}
            onKeyDown={handleFieldKeydown}
            onChange={handleFieldChange}
          />
        </Box>
      </Grid>

      <Grid
        xs={12}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
        paddingBottom={3}
      >
        <Box
          sx={{
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <NumericInput
            id="outlined-disabled"
            label={productListingFiltersStrings.inputs.product.id}
            size="small"
            name={SOURCE_PRODUCT_ID}
            value={filters[SOURCE_PRODUCT_ID]}
            setValue={(val) =>
              handleFieldChange({
                target: { name: SOURCE_PRODUCT_ID, value: val },
              })
            }
            onKeyDown={handleFieldKeydown}
          />
        </Box>
        <Box
          flexGrow="1"
          sx={{
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <TextField
            id="outlined-disabled"
            label={productListingFiltersStrings.inputs.product.label}
            size="small"
            name={PRODUCT_LABEL}
            value={filters[PRODUCT_LABEL]}
            onKeyDown={handleFieldKeydown}
            onChange={handleFieldChange}
          />
        </Box>
      </Grid>
    </Grid>
  );
});

ProductListingFilters.propTypes = {
  allowSearch: PropTypes.bool.isRequired,
  validVisual: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  onSetValidVisual: PropTypes.func,
  onIncludeSocialAccounts: PropTypes.func,
  resetProductsTable: PropTypes.func.isRequired,
  getProductsData: PropTypes.func.isRequired,
  showValidVisualCheckbox: PropTypes.bool,
  showSocialAccountsCheckbox: PropTypes.bool.isRequired,
};
