import { useState } from "react";
import PropTypes from "prop-types";

import { TablePagination, Box, IconButton, Pagination } from "@mui/material/";
import { NumericInput } from "../numericInput/NumericInput";
import { ExpandMore, ArrowRightAlt } from "@mui/icons-material";
import { dataTableStrings } from "./locale";
import {
  EXCLUDED_NUMERIC_FIELD_CHARS,
  TABLE_ROWS_PER_PAGE,
} from "../../../constants";
import "./style.scss";

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const [goToPageValue, setGoToPageValue] = useState("");
  const totalPages = Math.ceil(count / rowsPerPage);

  const handleGoToPage = (event) => {
    if (!goToPageValue || goToPageValue > totalPages || goToPageValue < 1) {
      return;
    }

    onPageChange(event, parseInt(goToPageValue, 10) - 1);
  };

  const handleGoToPageKeydown = (event) => {
    if (EXCLUDED_NUMERIC_FIELD_CHARS.includes(event.key)) {
      event.preventDefault();
    }

    if (event.key === "Enter") {
      event.stopPropagation();
      handleGoToPage();
    }
  };

  const handlePageChange = (event, selectedPage) => {
    onPageChange(event, selectedPage - 1);
  };

  const getItemAriaLabel = (type, page, select) => {
    if (type === "previous") {
      return dataTableStrings.previousPage;
    }

    if (type === "next") {
      return dataTableStrings.nextPage;
    }

    if (type === "page" && select) {
      return dataTableStrings.formatString(dataTableStrings.pageX, page);
    }

    if (type === "page") {
      return dataTableStrings.formatString(dataTableStrings.goToPageX, page);
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 1 }}>
      <Pagination
        className="data-table-pagination__nav"
        count={totalPages || 1}
        page={page + 1}
        color="primary"
        disabled={!totalPages}
        getItemAriaLabel={getItemAriaLabel}
        onChange={handlePageChange}
      />
      <NumericInput
        id="go-to-page"
        className="goto-page__field"
        label={dataTableStrings.goTo}
        variant="outlined"
        size="small"
        disabled={!totalPages}
        value={goToPageValue}
        setValue={setGoToPageValue}
        onKeyDown={handleGoToPageKeydown}
      />
      <IconButton
        className="goto-page__button"
        onClick={handleGoToPage}
        disabled={
          !totalPages ||
          !goToPageValue ||
          goToPageValue > totalPages ||
          goToPageValue < 1
        }
        aria-label={dataTableStrings.formatString(
          dataTableStrings.goToPageX,
          goToPageValue,
        )}
      >
        <ArrowRightAlt sx={{ fontSize: 20 }} />
      </IconButton>
    </Box>
  );
};

export const DataTablePagination = ({
  page,
  rowsPerPage,
  totalRecords,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  return (
    <TablePagination
      className="data-table-pagination"
      component="div"
      rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
      count={totalRecords}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: {
          "aria-label": dataTableStrings.rowsPerPage,
        },
        IconComponent: ExpandMore,
        native: true,
      }}
      labelRowsPerPage={dataTableStrings.rowsPerPage}
      labelDisplayedRows={() =>
        dataTableStrings.formatString(
          dataTableStrings.xOutOfY,
          page + 1,
          totalPages || 1,
        )
      }
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
      sx={{ mt: 2 }}
    />
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.oneOf(TABLE_ROWS_PER_PAGE),
  onPageChange: PropTypes.func,
};

DataTablePagination.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.oneOf(TABLE_ROWS_PER_PAGE),
  totalRecords: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
};
