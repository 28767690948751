import { LANGUAGES, MEDIA_CHANNELS, MAPPING_KEYS_STATUSES } from "../constants";

export const isDevelopment = process.env.NODE_ENV === "development";

export const noop = () => {};

export const debug = (() => {
  const methods = {
    log: noop,
    info: noop,
    warn: noop,
    error: noop,
  };

  if (isDevelopment) {
    Object.assign(methods, {
      log: console.log.bind(
        console,
        "%cLOG",
        "color: white; background-color: grey; padding: 2px 4px",
      ),
      info: console.info.bind(
        console,
        "%cINFO",
        "color: white; background-color: blue; padding: 2px 4px",
      ),
      warn: console.warn.bind(
        console,
        "%cWARN",
        "color: black; background-color: orange; padding: 2px 4px",
      ),
      error: console.error.bind(
        console,
        "%cERROR",
        "color: white; background-color: red; padding: 2px 4px",
      ),
    });
  }

  return methods;
})();

export const initLocalization = (strings) => {
  strings.setLanguage(localStorage.getItem("lang") ?? LANGUAGES.fr);
};

export const createSocialPageUrl = (mediaChannel, accountName) => {
  switch (mediaChannel) {
    case MEDIA_CHANNELS.tiktok:
      return `https://${mediaChannel.toLowerCase()}.com/@${accountName
        .toLowerCase()
        .replace(/ /g, "")}`;

    case MEDIA_CHANNELS.snapchat:
      return `https://${mediaChannel.toLowerCase()}.com/add/${accountName
        .toLowerCase()
        .replace(/ /g, "")}`;

    default:
      return `https://${mediaChannel.toLowerCase()}.com/${accountName
        .toLowerCase()
        .replace(/ /g, "")}`;
  }
};

export const createMappingStatusClasses = (row, value) => {
  const productDefaultClass = "product-default";

  const { isLocked, isLockedByCurrentUser } = row;
  const lockedByOther = isLocked && !isLockedByCurrentUser;

  const classMapping = {
    [MAPPING_KEYS_STATUSES.validated]: lockedByOther
      ? productDefaultClass
      : "product-validated",
    [MAPPING_KEYS_STATUSES.autoDomainMapped]: lockedByOther
      ? productDefaultClass
      : "product-auto-domain-mapped",
    [MAPPING_KEYS_STATUSES.manualDomainMapped]: lockedByOther
      ? productDefaultClass
      : "product-manual-domain-mapped",
    [MAPPING_KEYS_STATUSES.manual]: lockedByOther
      ? productDefaultClass
      : "product-manual",
    [MAPPING_KEYS_STATUSES.auto]: lockedByOther
      ? productDefaultClass
      : "product-auto",
    [MAPPING_KEYS_STATUSES.rejected]: lockedByOther
      ? productDefaultClass
      : "product-rejected",
    [MAPPING_KEYS_STATUSES.reject]: lockedByOther
      ? productDefaultClass
      : "product-rejected",
    [MAPPING_KEYS_STATUSES.waitingProductCreated]: lockedByOther
      ? productDefaultClass
      : "product-created",
    [MAPPING_KEYS_STATUSES.waitingProductRequested]: lockedByOther
      ? productDefaultClass
      : "product-requested",
    [MAPPING_KEYS_STATUSES.notMapped]: lockedByOther
      ? productDefaultClass
      : "product-not-mapped",
  };

  return classMapping[value] || "default-class";
};
