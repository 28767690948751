import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Container, Box, Paper, Button } from "@mui/material";
import { AppAlert } from "../../components/common/appAlert/AppAlert";
import LoginIcon from "@mui/icons-material/Login";
import { initLocalization } from "../../utils/helpers";
import { globalStrings } from "../../global.locale";
import { loginStrings } from "./locale";
import kantarLogo from "../../assets/kantar-media-logo.svg";
import { API_ROUTE_SIGN_IN } from "../../routes";
import "./style.scss";

export const Login = () => {
  initLocalization(globalStrings);
  initLocalization(loginStrings);

  const [query] = useSearchParams();
  const [error, setError] = useState(
    globalStrings.errors[query.get("message")],
  );

  const handleCloseError = () => setError(false);

  return (
    <Container className="kantar-login" maxWidth="xs">
      <AppAlert
        key={error}
        open={Boolean(error)}
        type="error"
        onClose={handleCloseError}
      >
        {error}
      </AppAlert>
      <Box className="kantar-login__box">
        <Paper className="kantar-login__paper">
          <img
            className="kantar-login__logo"
            src={kantarLogo}
            alt="Kantar Media logo"
          />
          <div className="kantar-login__button-container">
            <Button
              variant="contained"
              startIcon={<LoginIcon />}
              onClick={() => {
                window.location = API_ROUTE_SIGN_IN;
              }}
            >
              {loginStrings.loginWithMs}
            </Button>
          </div>
        </Paper>
      </Box>
    </Container>
  );
};
