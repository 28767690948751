import LocalizedStrings from "react-localization";

export const poolMetadataStrings = new LocalizedStrings({
  en: {
    metadataTitle: "Metadata",
    id: "ID",
    type: "Type",
    date: "Date",
    campaign: "Campaign",
    product: "Product",
    domain: "Domain",
    site: "Site",
    link: "Link",
  },
  fr: {
    metadataTitle: "Metadata",
    id: "ID",
    type: "Type",
    date: "Date",
    campaign: "Campaign",
    product: "Product",
    domain: "Domain",
    site: "Site",
    link: "Link",
  },
});
