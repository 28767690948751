import { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";

import { AppAlert } from "../../common/appAlert/AppAlert";
import { MediaListing } from "../mediaListing/MediaListing";
import { AdvertiserListing } from "../advertiserListing/AdvertiserListing";
import { ProductListing } from "../productListing/ProductListing";

import useRelationMapping from "../../../hooks/selfPromotion/useRelationMapping";

import { globalStrings } from "../../../global.locale";
import { mappingCreationStrings } from "./locale";
import {
  ACTIVATION_FILTER_OPTIONS,
  PREDEFINED_FILTER,
} from "../../../constants";

export const MappingsCreation = () => {
  const [error, setError] = useState("");
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const { isLoading: actionLoading, addMappings } = useRelationMapping();

  const disableAddButton = () => {
    return (
      !selectedMedia.length ||
      (selectedMedia.length &&
        !selectedAdvertisers.length &&
        !selectedProducts.length) ||
      actionLoading
    );
  };

  const handleAddMappings = () => {
    const payload = {
      parentIds: selectedMedia.map((item) => item.id),
      relatedIds: {
        products: selectedProducts.map((item) => item.productId),
        advertisers: selectedAdvertisers.map((item) => item.id),
      },
    };
    console.log(payload);
    addMappings(payload).catch((err) => setError(err));
  };

  const handleCloseError = () => setError(false);

  return (
    <>
      <AppAlert
        key={error}
        open={Boolean(error)}
        type="error"
        onClose={handleCloseError}
      >
        {globalStrings.errors[error] || globalStrings.errors.generic}
      </AppAlert>

      <Grid
        className="mappings-creation"
        container
        direction="column"
        flexWrap="nowrap"
        py={6}
        px={{ xs: 2, md: 6 }}
        disableEqualOverflow
        sx={{ height: "100%", overflow: "auto" }}
      >
        <Grid xs={12} flexGrow={1}>
          <Grid
            container
            columns={12}
            rowSpacing={{ xs: 3, md: 0 }}
            columnSpacing={{ xs: 0, md: 2 }}
            height="100%"
          >
            <Grid md={4} xs={12}>
              <MediaListing
                predefinedFilter={PREDEFINED_FILTER.selfPromotion}
                onSelectedMedia={setSelectedMedia}
                isLoading={actionLoading}
              />
            </Grid>
            <Grid md={4} xs={12}>
              <AdvertiserListing
                onSelectedAdvertisers={setSelectedAdvertisers}
                isLoading={actionLoading}
              />
            </Grid>
            <Grid md={4} xs={12}>
              <ProductListing
                activationFilterOption={ACTIVATION_FILTER_OPTIONS.selfPromotion}
                onSelectedProducts={setSelectedProducts}
                isLoading={actionLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} paddingTop={3} sx={{ textAlign: "right" }}>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            size="large"
            disabled={disableAddButton()}
            onClick={handleAddMappings}
          >
            {mappingCreationStrings.addMappings}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
