import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_USER_ROLES } from "../../routes";

export default function useGetUsers(auto = false) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorUsers, setErrorUsers] = useState(false);
  const [users, setUsers] = useState([]);

  const getUsers = (name = "", page = 1, size = 100) => {
    setIsLoading(true);
    setErrorUsers(false);
    Api.get(API_ROUTE_USER_ROLES, {
      params: {
        kwd: name,
        page: page,
        size: size,
      },
    })
      .then((response) => {
        setUsers(response.data.data);
        if (response.data.data.length <= 0) {
          setErrorUsers(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!auto) {
      return;
    }
    getUsers();
  }, [auto]);

  return {
    isLoading,
    errorUsers,
    users,
    getUsers,
  };
}
