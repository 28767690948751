import { useState } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_PATHMATICS_VALID_VISUAL } from "../../routes";
import { debug } from "../../utils/helpers";

export function useValidVisual() {
  const [isLoading, setIsLoading] = useState(false);

  const setValidVisual = (payload = {}) => {
    setIsLoading(true);

    return Api.put(API_ROUTE_PATHMATICS_VALID_VISUAL, payload)
      .catch((error) => {
        debug.log(error);
        return Promise.reject(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    setValidVisual,
  };
}
