import LocalizedStrings from "react-localization";

export const productListingFiltersStrings = new LocalizedStrings({
  en: {
    search: "Search",
    validVisual: "Valid Visual",
    includeSocialAccounts: "Social accounts",
    inputs: {
      advertiser: {
        id: "Advertiser ID",
        label: "Advertiser Label",
      },
      product: {
        id: "Product ID",
        label: "Product Label",
      },
    },
  },
  fr: {
    search: "Recherche",
    validVisual: "Valid Visual",
    includeSocialAccounts: "Social accounts",
    inputs: {
      advertiser: {
        id: "ID annonceur",
        label: "Libellé annonceur",
      },
      product: {
        id: "ID produit",
        label: "Libellé produit",
      },
    },
  },
});
