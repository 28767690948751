import LocalizedStrings from "react-localization";

export const lockTimerStrings = new LocalizedStrings({
  en: {
    lockExpiresIn: "Lock expires in",
  },
  fr: {
    lockExpiresIn: "Les lignes seront déverouillées dans",
  },
});
