import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_MAPPING_STATUSES } from "../../routes";

export default function useGetMappingStatuses(auto = false) {
  const [mappingStatuses, setMappingStatuses] = useState([]);

  const getMappingStatuses = () => {
    Api.get(API_ROUTE_MAPPING_STATUSES)
      .then((res) => {
        setMappingStatuses(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!auto) {
      return;
    }
    getMappingStatuses();
  }, [auto]);

  return {
    mappingStatuses,
    getMappingStatuses,
  };
}
