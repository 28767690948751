import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  Typography,
  Box,
  TextField,
  Modal,
  MenuItem,
} from "@mui/material/";
import {
  FilterListOutlined,
  CancelOutlined,
  SendOutlined,
} from "@mui/icons-material";
import { NumericInput } from "../../common/numericInput/NumericInput";
import { MappingStatuses } from "../mappingStatuses/MappingStatuses";
import useGetMappingStatuses from "../../../hooks/pathmatics/useGetMappingStatuses";
import { initLocalization } from "../../../utils/helpers";
import { mappingFilterModalStrings } from "./locale";
import {
  ADVERTISER_ID,
  DEFAULT_MAPPING_KEYS_FILTERS,
  PRODUCT_ID,
} from "../../../constants";
import "./style.scss";

const defaultState = {
  advertiserId: DEFAULT_MAPPING_KEYS_FILTERS.advertiserId,
  productId: DEFAULT_MAPPING_KEYS_FILTERS.productId,
  socialAccountName: DEFAULT_MAPPING_KEYS_FILTERS.socialAccountName,
  socialAccountFriendlyName:
    DEFAULT_MAPPING_KEYS_FILTERS.socialAccountFriendlyName,
  threshold: DEFAULT_MAPPING_KEYS_FILTERS.threshold,
  mappingStatus: DEFAULT_MAPPING_KEYS_FILTERS.mappingStatus,
};

export const MappingFilterModal = ({ onApplyFilters, filters }) => {
  initLocalization(mappingFilterModalStrings);

  const [open, setOpen] = useState(false);
  const [modalFilters, setModalFilters] = useState(defaultState);

  const { mappingStatuses } = useGetMappingStatuses(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSetFilters = (e) => {
    setModalFilters({
      ...modalFilters,
      [e.target.name]: e.target.value,
    });
  };

  const handleApplyFilters = () => {
    onApplyFilters(modalFilters);
    handleClose();
  };

  useEffect(() => {
    setModalFilters({
      advertiserId: filters.advertiserId,
      productId: filters.productId,
      socialAccountName: filters.socialAccountName,
      socialAccountFriendlyName: filters.socialAccountFriendlyName,
      threshold: filters.threshold,
      mappingStatus: filters.mappingStatus,
    });
  }, [filters]);

  return (
    <Grid>
      <Button
        startIcon={<FilterListOutlined />}
        variant="contained"
        color="secondary"
        onClick={handleOpen}
        sx={{ width: "100%" }}
      >
        {mappingFilterModalStrings.filterButton}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="filter-modal-title"
        aria-describedby="filter-modal-description"
      >
        <Box className="mappings-filter__modal-box">
          <Typography
            id="filter-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginBottom: "1rem" }}
          >
            {mappingFilterModalStrings.title}
          </Typography>
          <Grid
            id="filter-modal-description"
            container
            flexDirection="column"
            gap="1.5rem"
          >
            <Grid container gap="1rem">
              <Grid flex={1}>
                <NumericInput
                  label={mappingFilterModalStrings.advertiserId}
                  size="small"
                  name={ADVERTISER_ID}
                  value={modalFilters[ADVERTISER_ID]}
                  setValue={(val) =>
                    handleSetFilters({
                      target: { name: ADVERTISER_ID, value: val },
                    })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  }}
                  sx={{ "&": { width: "100%" } }}
                />
              </Grid>
              <Grid flex={1}>
                <NumericInput
                  label={mappingFilterModalStrings.productId}
                  size="small"
                  name={PRODUCT_ID}
                  value={modalFilters[PRODUCT_ID]}
                  setValue={(val) =>
                    handleSetFilters({
                      target: { name: PRODUCT_ID, value: val },
                    })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  }}
                  sx={{ "&": { width: "100%" } }}
                />
              </Grid>
            </Grid>
            <TextField
              sx={{
                "&": { width: "100%", background: "#fff" },
                "& > :not(style)": { width: "100%" },
              }}
              label={mappingFilterModalStrings.socialAccountName}
              value={modalFilters.socialAccountName}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                }
              }}
              onChange={handleSetFilters}
              size="small"
              name="socialAccountName"
            />
            <TextField
              sx={{
                "&": { width: "100%", background: "#fff" },
                "& > :not(style)": { width: "100%" },
              }}
              label={mappingFilterModalStrings.socialAccountFriendlyName}
              value={modalFilters.socialAccountFriendlyName}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                }
              }}
              onChange={handleSetFilters}
              size="small"
              name="socialAccountFriendlyName"
            />
            <Grid container gap="1rem">
              <Grid flex={1}>
                <MappingStatuses
                  handleMappingStatusChange={handleSetFilters}
                  mappingStatus={modalFilters.mappingStatus}
                  mappingStatuses={mappingStatuses}
                />
              </Grid>
              <Grid flex={1}>
                <TextField
                  id="outlined-select-currency"
                  select
                  size="small"
                  label={mappingFilterModalStrings.threshold}
                  value={modalFilters.threshold}
                  sx={{ "&": { width: "100%" } }}
                  onChange={handleSetFilters}
                  name="threshold"
                >
                  <MenuItem key="everything" value="None">
                    {mappingFilterModalStrings.thresholdNone}
                  </MenuItem>
                  <MenuItem key="below" value="BelowThreshold">
                    {mappingFilterModalStrings.belowThreshold}
                  </MenuItem>
                  <MenuItem key="above" value="AboveThreshold">
                    {mappingFilterModalStrings.aboveThreshold}
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid container gap="0.625rem" justifyContent="end" paddingTop="3rem">
            <Button
              startIcon={<CancelOutlined />}
              variant="outlined"
              onClick={handleClose}
            >
              {mappingFilterModalStrings.cancel}
            </Button>
            <Button
              startIcon={<SendOutlined />}
              variant="contained"
              onClick={handleApplyFilters}
            >
              {mappingFilterModalStrings.applyFilters}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
};

MappingFilterModal.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};
