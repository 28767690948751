import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_ADVERTISER_LISTING } from "../../routes";

export default function useAdvertiserListing(auto = false) {
  const [isLoading, setIsLoading] = useState(false);
  const [advertiserData, setAdvertiserData] = useState([]);

  const getAdvertiserData = (payload = {}, showLoader = true) => {
    setIsLoading(showLoader);

    Api.post(API_ROUTE_ADVERTISER_LISTING, {
      ...payload,
    })
      .then((response) => {
        setAdvertiserData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!auto) {
      return;
    }
    getAdvertiserData();
  }, [auto]);

  return {
    isLoading,
    advertiserData,
    setAdvertiserData,
    getAdvertiserData,
  };
}
