import { MEDIA_CHANNELS } from "../../../constants";
import PropTypes from "prop-types";
import {
  Facebook,
  Instagram,
  Snapchat,
  TikTok,
} from "../../../assets/customIcons";

export const SocialPageIcon = ({ mediaChannel }) => {
  switch (mediaChannel) {
    case MEDIA_CHANNELS.facebook:
      return <Facebook />;

    case MEDIA_CHANNELS.instagram:
      return <Instagram />;

    case MEDIA_CHANNELS.snapchat:
      return <Snapchat />;

    case MEDIA_CHANNELS.tiktok:
      return <TikTok />;

    default:
      return "";
  }
};

SocialPageIcon.propTypes = {
  mediaChannel: PropTypes.string.isRequired,
};
