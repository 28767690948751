import LocalizedStrings from "react-localization";

export const mappingKeysFiltersStrings = new LocalizedStrings({
  en: {
    mediaType: "Media Type",
    category: "Category",
    media: "Media",
    advertiser: "Advertiser",
    product: "Product",
    search: "Search",
  },
  fr: {
    mediaType: "Type de Média",
    category: "Catégorie",
    media: "Média",
    advertiser: "Annonceur",
    product: "Produit",
    search: "Recherche",
  },
});
