import { Header } from "../components/common/header/Header";
import PropTypes from "prop-types";

export const DefaultLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
