import { experimental_extendTheme as extendTheme } from "@mui/material/styles";

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: "#6750A4",
        },
        secondary: {
          main: "#625B71",
        },
        error: {
          main: "#B91B42",
        },
        success: {
          main: "#26BB6A",
        },
        background: {
          paper: "#FFFBFE",
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    button: {
      lineHeight: 1.67,
      letterSpacing: 0.1,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: 12,
          borderRadius: 4,
        },
        contained: {
          "&:disabled": {
            color: "#AEAAAE",
            backgroundColor: "#F4EFF4",
          },
        },
        outlined: {
          "&:disabled": {
            color: "#AEAAAE",
            borderColor: "#AEAAAE",
          },
        },
        containedSecondary: {
          color: "#49454F",
          backgroundColor: "#E8DEF8",
          "&:hover": {
            backgroundColor: "#CCC2DC",
          },
          "&:focus-visible": {
            backgroundColor: "#B0A7C0",
          },
        },
        textSecondary: ({ theme }) => ({
          "&:hover": {
            color: theme.palette.primary.main,
          },
        }),
        outlinedPrimary: ({ theme }) => ({
          borderColor: theme.palette.primary.main,
        }),
        outlinedError: ({ theme }) => ({
          borderColor: theme.palette.error.main,
        }),
        outlinedSuccess: ({ theme }) => ({
          borderColor: theme.palette.success.main,
        }),
        startIcon: ({ ownerState }) => ({
          "&>:first-of-type": {
            fontSize: 18,
          },
          ...(ownerState.size === "large" && {
            marginLeft: -8,
          }),
        }),
        sizeLarge: {
          padding: "10px 24px",
        },
        outlinedSizeLarge: {
          padding: "9px 23px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#F6EDFF",
          color: "#000",
          boxShadow: "none",
          borderBottom: "1px solid #CAC4D0",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up("sm")]: {
            minHeight: 48,
            paddingLeft: 12,
            paddingRight: 16,
          },
        }),
      },
    },
    MuiListItem: {
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&:hover": {
            backgroundColor: "#F6EDFF",
          },
          "&.active": {
            backgroundColor: theme.palette.action.selected,
          },
          ...theme.unstable_sx({
            py: 1.25,
          }),
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: "body2",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          border: "none",
          padding: "8px",
          fontSize: "12px",
          overflowWrap: "break-word",
        },
        head: {
          fontSize: "12px",
          backgroundColor: "#F6EDFF",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        spacer: {
          display: "none",
        },
        toolbar: ({ theme }) => ({
          [theme.breakpoints.up("sm")]: {
            padding: 0,
          },
        }),
        input: {
          marginLeft: 0,
          marginRight: 8,
          border: "1px solid #939094",
          order: -1,
        },
        select: {
          textAlign: "left",
          textAlignLast: "left",
        },
        selectIcon: {
          fontSize: 16,
          marginRight: 4,
        },
        selectLabel: {
          flexGrow: 1,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          borderRadius: 4,
        },
        input: {
          '&[type="number"]': {
            appearance: "textfield",
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 28,
          height: 16,
          padding: 0,
          display: "flex",
          "&:active": {
            "& .MuiSwitch-thumb": {
              width: 15,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              transform: "translateX(9px)",
            },
          },
        },
        switchBase: {
          padding: 2,
          "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
              opacity: 1,
            },
          },
        },
        thumb: (theme) => ({
          boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
          width: 12,
          height: 12,
          borderRadius: 6,
        }),
        track: {
          borderRadius: 16 / 2,
          opacity: 1,
          background: "rgba(0, 0, 0, 0.35)",
          boxSizing: "border-box",
        },
      },
    },
  },
});
