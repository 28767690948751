import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  MenuItem,
  TextField,
} from "@mui/material/";
import { AppAlert } from "../appAlert/AppAlert";
import { RestartAltOutlined, SearchOutlined } from "@mui/icons-material";

import useGetUsers from "../../../hooks/common/useGetUsers";
import useGetUserRoles from "../../../hooks/common/useGetUserRoles";
import useUpdateUserRole from "../../../hooks/common/useUpdateUserRole";

import { initLocalization } from "../../../utils/helpers";
import { usersListStrings } from "./locale";
import "./style.scss";

export const UsersList = () => {
  initLocalization(usersListStrings);

  const [userName, setUserName] = useState("");
  const [disableSearch, setDisableSearch] = useState(true);

  const { userRoles } = useGetUserRoles(true);
  const { isLoading, errorUsers, users, getUsers } = useGetUsers(true);
  const {
    openSnackbar,
    setOpenSnackbar,
    addUserRole,
    modifyUserRole,
    deleteUserRole,
  } = useUpdateUserRole();

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const handleChangeSearchUser = (event) => {
    event.target.value.length >= 2
      ? setDisableSearch(false)
      : setDisableSearch(true);

    setUserName(event.target.value);
  };

  const handleChangeUserRoles = (event, userId, role) => {
    if (role === undefined || role === null) {
      addUserRole(userId, event.target.value);
    } else if (event.target.value === "No role") {
      deleteUserRole(userId);
    } else {
      modifyUserRole(userId, event.target.value);
    }
    if (userName.length > 0) {
      getUsers(userName);
    } else {
      getUsers();
    }
  };

  return (
    <div className="users-list">
      <AppAlert
        open={openSnackbar}
        type="success"
        onClose={handleCloseSnackbar}
      >
        {usersListStrings.successMessage}
      </AppAlert>
      <Grid container alignItems="center">
        <Grid container>
          <h2 className="users-list__title">{usersListStrings.title}</h2>
        </Grid>
      </Grid>
      <div className="users-list__list">
        <Grid container alignItems="center" paddingTop={1} paddingBottom={1}>
          <Grid
            xs={12}
            md={8}
            paddingLeft={2}
            paddingTop={1}
            paddingRight={2}
            paddingBottom={1}
          >
            <TextField
              id="outlined-basic"
              sx={{
                "&": { width: "100%", background: "#fff" },
                "& > :not(style)": { width: "100%" },
              }}
              label={usersListStrings.search}
              variant="outlined"
              value={userName}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                  if (!disableSearch) {
                    getUsers(userName);
                  }
                }
              }}
              onChange={handleChangeSearchUser}
            />
          </Grid>
          <Grid
            xs={6}
            md={2}
            paddingTop={1}
            paddingRight={0.5}
            paddingBottom={1}
            paddingLeft={2}
          >
            <Button
              variant="outlined"
              sx={{ "&": { width: "100%" } }}
              startIcon={<RestartAltOutlined />}
              onClick={() => {
                setDisableSearch(true);
                setUserName("");
                getUsers();
              }}
            >
              {usersListStrings.reset}
            </Button>
          </Grid>
          <Grid
            xs={6}
            sm={6}
            md={2}
            paddingTop={1}
            paddingRight={2}
            paddingBottom={1}
            paddingLeft={0.5}
          >
            <Button
              variant="contained"
              sx={{ "&": { width: "100%" } }}
              disabled={disableSearch}
              startIcon={<SearchOutlined />}
              onClick={() => getUsers(userName)}
            >
              {usersListStrings.search}
            </Button>
          </Grid>
        </Grid>
        {isLoading && (
          <Grid container paddingLeft={3} paddingRight={3}>
            <p>{usersListStrings.loading}</p>
          </Grid>
        )}
        {errorUsers && (
          <Grid
            container
            paddingLeft={3}
            paddingRight={3}
            sx={{ "&": { color: "#B3261E" } }}
          >
            <p>{usersListStrings.userNotFound}</p>
          </Grid>
        )}
        {users && (
          <List sx={{ "&": { padding: "0.5rem" } }}>
            {users.map((user) => (
              <ListItem
                key={user.userId}
                disablePadding={false}
                sx={{
                  "&": { width: "100%" },
                  "& > :not(style)": { width: "100%" },
                  "&:nth-of-type(even)": { backgroundColor: "#F4EFF4" },
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  gap="1rem"
                >
                  <Grid flex="2 1 55%">
                    <ListItemText primary={user.name} />
                  </Grid>
                  <Grid flex="1 1 40%">
                    <Box
                      sx={{
                        "& .MuiTextField-root": {
                          width: "100%",
                        },
                      }}
                    >
                      <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        label={usersListStrings.role}
                        defaultValue={!user.role ? "No role" : user.role.id}
                        onChange={(e) =>
                          handleChangeUserRoles(e, user.userId, user.role)
                        }
                      >
                        <MenuItem key="noRole" value="No role">
                          {usersListStrings.noRole}
                        </MenuItem>
                        {userRoles.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {usersListStrings[option.name]}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </div>
  );
};
