import { useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Threshold } from "../../components/common/threshold/Threshold";
import { UsersList } from "../../components/common/usersList/UsersList";
import { Unauthorized } from "../unauthorized/Unauthorized";
import { DefaultLayout } from "../../layouts/DefaultLayout";
import { AppContext } from "../root/Root";
import "./style.scss";

export const Admin = () => {
  const { isAdmin } = useContext(AppContext);

  return (
    <DefaultLayout>
      {isAdmin ? (
        <Grid
          className="admin-wrapper"
          container
          flexDirection="column"
          gap="1rem"
          margin="0 auto"
          maxWidth={1312}
        >
          <Threshold />
          <UsersList />
        </Grid>
      ) : (
        <Unauthorized />
      )}
    </DefaultLayout>
  );
};
