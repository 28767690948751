import LocalizedStrings from "react-localization";

export const usersListStrings = new LocalizedStrings({
  en: {
    title: "Users List",
    reset: "Reset",
    search: "Search",
    loading: "Loading users...",
    userNotFound: "User not found",
    role: "Role",
    noRole: "No Role",
    Administrator: "Administrator",
    Operator: "Operator",
    successMessage: "Changes saved!",
  },
  fr: {
    title: "Liste des utilisateurs",
    reset: "Réinitialiser",
    search: "Recherche",
    loading: "Chargement des utilisateurs ...",
    userNotFound: "Utilisateur non trouvé",
    role: "Rôle",
    noRole: "Pas de rôle",
    Administrator: "Administrateur",
    Operator: "Opérateur",
    successMessage: "Changements sauvegardés!",
  },
});
