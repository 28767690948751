import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import {
  API_ROUTE_BIS_REVIEW_CREATIVE,
  API_ROUTE_BIS_REVIEW_DETAILS,
} from "../../routes";
import { debug } from "../../utils/helpers";
import { DEFAULT_CREATIVE_REVIEW_FILTERS } from "../../constants";

export default function useReviewCreative(auto = false) {
  const [isLoading, setIsLoading] = useState(false);
  const [creatives, setCreatives] = useState([]);
  const [creativeDetails, setCreativeDetails] = useState([]);

  const getCreatives = (payload = {}, showLoader = true) => {
    setIsLoading(showLoader);

    return Api.post(API_ROUTE_BIS_REVIEW_CREATIVE, {
      ...DEFAULT_CREATIVE_REVIEW_FILTERS,
      ...payload,
    })
      .then((response) => {
        setCreatives(response.data);
      })
      .catch((error) => {
        debug.log(error);
        return Promise.reject(error?.response?.data?.errors?.Filter);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCreativeDetails = (payload = {}, showLoader = true) => {
    setIsLoading(showLoader);

    return Api.post(API_ROUTE_BIS_REVIEW_DETAILS, {
      ...payload,
    })
      .then((response) => {
        setCreativeDetails(response.data);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        debug.log(error);
        return Promise.reject(error?.response?.data?.errors?.Filter);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!auto) {
      return;
    }
    getCreatives();
  }, [auto]);

  return {
    isLoading,
    creatives,
    getCreatives,
    setCreatives,
    creativeDetails,
    getCreativeDetails,
  };
}
