import { useContext, useState } from "react";
import { AppContext } from "../../root/Root";
import { DefaultLayout } from "../../../layouts/DefaultLayout";
import Grid from "@mui/material/Unstable_Grid2";
import Modal from "@mui/material/Modal";
import { Close as CloseIcon, BlockOutlined } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";

import { AppAlert } from "../../../components/common/appAlert/AppAlert";
import { MappingKeys } from "../../../components/selfPromotion/mappingKeys/MappingKeys";
import { MappingsCreation } from "../../../components/selfPromotion/mappingsCreation/MappingsCreation";

import useRelationMapping from "../../../hooks/selfPromotion/useRelationMapping";

import { globalStrings } from "../../../global.locale";
import { actionButtonsStrings } from "./locale";
import { initLocalization } from "../../../utils/helpers";
import "../../../style/common.scss";

export const MappingsView = () => {
  useContext(AppContext);

  initLocalization(globalStrings);
  initLocalization(actionButtonsStrings);

  const [error, setError] = useState(null);
  const [selectedMappingKeys, setSelectedMappingKeys] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [refreshMappingKeys, setRefreshMappingKeys] = useState(null);

  const {
    isLoading: actionLoading,
    rejectMappingKeys,
    success: validateSuccess,
  } = useRelationMapping();

  const handleRejectMappingKeys = () => {
    rejectMappingKeys(selectedMappingKeys.map((row) => row.id)).catch((err) => {
      setError(err);
    });
  };

  const handleCloseError = () => setError(false);

  const handleCloseModal = () => {
    setOpenModal(false);
    setRefreshMappingKeys(new Date().getTime());
  };

  return (
    <DefaultLayout>
      <AppAlert
        key={error}
        open={Boolean(error)}
        type="error"
        onClose={handleCloseError}
      >
        {globalStrings.errors[error] || globalStrings.errors.generic}
      </AppAlert>
      <Grid
        className="page-content"
        container
        rowSpacing={{ xs: 3, md: 0 }}
        columnSpacing={{ xs: 0, md: 2 }}
        padding={{ xs: 1, md: 2 }}
        disableEqualOverflow
      >
        <Grid md={11}>
          <MappingKeys
            onMappingKeysSelection={setSelectedMappingKeys}
            shouldRefresh={validateSuccess || refreshMappingKeys}
            isLoading={actionLoading}
          />
        </Grid>
        <Grid
          display="flex"
          flexDirection={{ xs: "row", sm: "column" }}
          gap={2}
          paddingY={3}
          md={1}
        >
          <Button
            variant="outlined"
            size="large"
            onClick={() => setOpenModal(true)}
          >
            {actionButtonsStrings.add}
          </Button>
          <Button
            startIcon={<BlockOutlined />}
            variant="outlined"
            color="error"
            size="large"
            disabled={!selectedMappingKeys.length || actionLoading}
            onClick={handleRejectMappingKeys}
          >
            {actionButtonsStrings.reject}
          </Button>
        </Grid>
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-describedby="mappings-creation-modal"
        sx={{
          "&": { zIndex: (theme) => theme.zIndex.drawer - 1, top: 50 },
          "& .MuiModal-backdrop": { top: 50 },
        }}
      >
        <Grid
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "calc(100vh - 50px)",
            bgcolor: "#fff",
          }}
          className="mappings-creation-modal"
          id="mappings-creation-modal"
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon color="primary" sx={{ width: 20, height: 20 }} />
          </IconButton>
          <MappingsCreation />
        </Grid>
      </Modal>
    </DefaultLayout>
  );
};
