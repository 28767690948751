import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography, Link } from "@mui/material";
import dayjs from "dayjs";
import { MAPPING_KEYS_DATE_FORMAT } from "../../../constants";

import { createSocialPageUrl, initLocalization } from "../../../utils/helpers";
import { poolMetadataStrings } from "./locale";
import { viewMappingDetailsStrings } from "../viewMappingDetails/locale";
import "./style.scss";

export const PoolMetadata = ({ creativeDetails }) => {
  initLocalization(poolMetadataStrings);
  initLocalization(viewMappingDetailsStrings);

  return (
    <Grid>
      <Typography paddingLeft="0.5rem" fontWeight="bold">
        {poolMetadataStrings.metadataTitle}:
      </Typography>
      <Grid className="pool-metadata" container flexDirection="column">
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.id}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {creativeDetails.creativeId}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.date}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {dayjs(creativeDetails.firstOccurrence).format(
              MAPPING_KEYS_DATE_FORMAT,
            )}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {viewMappingDetailsStrings.socialAccountName}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {creativeDetails.socialAccount.socialAccountName}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {viewMappingDetailsStrings.mediaChannel}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {creativeDetails.socialAccount.mediaChannelName}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.link}
          </Typography>
          <Typography className="pool-metadata__table-content">
            <Link
              href={
                creativeDetails.socialAccount.mediaChannelName.length > 0
                  ? createSocialPageUrl(
                      creativeDetails.socialAccount.mediaChannelName,
                      creativeDetails.socialAccount.socialAccountName,
                    )
                  : ""
              }
              target="_blank"
              role="link"
            >
              {poolMetadataStrings.link}
            </Link>
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {viewMappingDetailsStrings.description}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {creativeDetails.creativeText}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

PoolMetadata.propTypes = {
  creativeDetails: PropTypes.object.isRequired,
};
