import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_PRODUCT_LISTING } from "../../routes";

export default function useProductListing(
  activationFilterOption,
  auto = false,
) {
  const [isLoading, setIsLoading] = useState(false);
  const [productsData, setProductsData] = useState([]);

  const getProductsData = (payload = {}, showLoader = true) => {
    setIsLoading(showLoader);

    Api.post(API_ROUTE_PRODUCT_LISTING, {
      ...payload,
      activationFilterOption,
    })
      .then((response) => {
        setProductsData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!auto) {
      return;
    }
    getProductsData();
  }, [auto]);

  return {
    isLoading,
    productsData,
    setProductsData,
    getProductsData,
  };
}
