import LocalizedStrings from "react-localization";

export const actionButtonsStrings = new LocalizedStrings({
  en: {
    add: "Add",
    reject: "Reject",
  },
  fr: {
    add: "Add",
    reject: "Rejeter",
  },
});
