import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, TextField, Modal, Typography } from "@mui/material/";
import { AppAlert } from "../appAlert/AppAlert";
import { SaveOutlined } from "@mui/icons-material";

import useUpdateThreshold from "../../../hooks/common/useUpdateThreshold";

import { initLocalization } from "../../../utils/helpers";
import { thresholdStrings } from "./locale";
import "./style.scss";

export const Threshold = () => {
  initLocalization(thresholdStrings);

  const [openModal, setOpenModal] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [errorThreshold, setErrorThreshold] = useState(false);

  const {
    threshold,
    messageType,
    openSnackbar,
    setOpenSnackbar,
    setThreshold,
    getThreshold,
    updateThreshold,
  } = useUpdateThreshold(true);

  const handleCloseModal = () => setOpenModal(false);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const handleChangeThreshold = (event) => {
    event.target.value.length > 0
      ? setDisabledButton(false)
      : setDisabledButton(true);

    setThreshold(event.target.value);
  };

  const verifyThreshold = () => {
    const isNumber = /^\d+$/.test(threshold);

    if (isNumber) {
      setErrorThreshold(false);
      handleOpenModal();
    } else {
      setErrorThreshold(true);
    }
  };

  const sendThreshold = (threshold) => {
    if (threshold.length > 0) {
      updateThreshold(parseInt(threshold));
    }
  };

  return (
    <div className="threshold">
      <AppAlert
        open={openSnackbar}
        type={messageType}
        onClose={handleCloseSnackbar}
      >
        {thresholdStrings[messageType + "Message"]}
      </AppAlert>
      <Grid container alignItems="center">
        <Grid container>
          <h2 className="threshold__title">{thresholdStrings.title}</h2>
        </Grid>
      </Grid>
      <div className="threshold__content">
        <Grid container alignItems="center" paddingBottom={1} paddingTop={1}>
          <Grid
            xs={12}
            sm={8}
            md={10}
            paddingTop={1}
            paddingRight={2}
            paddingBottom={1}
            paddingLeft={2}
          >
            <TextField
              id="outlined-basic"
              sx={{
                "&": { width: "100%", background: "#fff" },
                "& > :not(style)": { width: "100%" },
              }}
              label={thresholdStrings.thresholdValue}
              variant="outlined"
              value={threshold === null ? 0 : threshold}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                  verifyThreshold();
                }
              }}
              onChange={handleChangeThreshold}
            />
          </Grid>
          <Grid
            xs={12}
            sm={4}
            md={2}
            paddingTop={1}
            paddingRight={2}
            paddingBottom={1}
            paddingLeft={2}
          >
            <Button
              variant="contained"
              sx={{ "&": { width: "100%" } }}
              startIcon={<SaveOutlined />}
              onClick={() => verifyThreshold()}
              disabled={disabledButton}
            >
              {thresholdStrings.save}
            </Button>
          </Grid>
          {errorThreshold && (
            <Grid paddingRight={2} paddingLeft={2}>
              <Typography sx={{ "&": { color: "red" } }}>
                {thresholdStrings.error}
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-describedby="modal-modal-description"
      >
        <Box className="threshold__modal-box">
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            textAlign="center"
          >
            {thresholdStrings.modalDescription}
          </Typography>
          <Grid container gap={2} justifyContent="center" p={2}>
            <Button
              variant="outlined"
              onClick={() => {
                getThreshold();
                handleCloseModal();
              }}
            >
              {thresholdStrings.modalCancel}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                sendThreshold(threshold);
                handleCloseModal();
              }}
            >
              {thresholdStrings.modalSubmit}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
